import { handleActions } from 'redux-actions';
import { fetchCluster } from './actions';

const clusterReducer = handleActions(
  {
    [fetchCluster.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchCluster.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoading: false,
      cluster: action.payload
    }),
    [fetchCluster.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    })
  },
  {
    isLoading: false,
    cluster: null
  }
);

export default clusterReducer;
