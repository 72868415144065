import React, { Component } from 'react';
import styled from 'styled-components';
import { withFormik, Form, Field } from 'formik';
import { SubmitButton } from 'components/button';

const Label = styled.label`
  margin-right: 5px;
`;
const ButtonRow = styled.div`
  margin-top: 5px;
  text-align: right;
`;
const TextRow = styled.div`
  margin-top: 5px;
  text-align: right;
`;

class LoginForm extends Component {
  componentDidMount() {
    this.emailInput.focus();
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <TextRow>
          <Label>Email</Label>
          <Field
            innerRef={input => {
              this.emailInput = input;
            }}
            type="text"
            name="email"
          />
        </TextRow>
        <TextRow>
          <Label>Password</Label>
          <Field type="password" name="password" />
        </TextRow>
        <ButtonRow>
          <SubmitButton buttonType="primary">Login</SubmitButton>
        </ButtonRow>
      </Form>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: ''
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values);
  }
})(LoginForm);
