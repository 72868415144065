import React, { Component } from 'react';
import styled from 'styled-components';
import { format as dateFormat } from 'date-fns';
import { AccountName, LedgerValue } from '../components';

const Root = styled.div`
  font-size: smaller;
  padding-top: 10px;
  padding-left: 5px;
  display: flex;
  background-color: ${props =>
    (props.index & 0x01) === 0x01 ? '#eeeeee' : 'default'};
`;
const HeaderRoot = styled.div`
  font-size: smaller;
  display: flex;
  color: #ffffff;
  padding: 5px 0px 5px 5px;
  background-color: #275384;
`;

const ColumnTradeDate = styled.div`
  flex: 0 0 15%;
`;
const ColumnAccountName = styled.div`
  flex: 0 0 35%;
  overflow: hidden;
`;

const ColumnComments = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;
const Comments = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ColumnAmount = styled.div`
  flex: 0 0 15%;
  text-align: right;
`;
const ColumnCommands = styled.div`
  flex: 0 0 5%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: right;
`;

const AmountHeader = styled.div`
  cursor: pointer;
`;

export const EntryRowHeader = ({ showUSD, onChangeShowUSD }) => {
  return (
    <HeaderRoot>
      <ColumnTradeDate>Date</ColumnTradeDate>
      <ColumnAccountName>Account</ColumnAccountName>
      <ColumnComments>Comments</ColumnComments>
      <ColumnAmount onClick={onChangeShowUSD}>
        <AmountHeader>Credit/Debit</AmountHeader>
      </ColumnAmount>
      <ColumnAmount onClick={onChangeShowUSD}>
        <AmountHeader>Balance</AmountHeader>
      </ColumnAmount>
      <ColumnCommands onClick={onChangeShowUSD}>
        <AmountHeader>{showUSD ? 'USD' : 'BTC'}</AmountHeader>
      </ColumnCommands>
    </HeaderRoot>
  );
};

const EntryRow = ({ index, entry, showUSD }) => {
  return (
    <Root index={index}>
      <ColumnTradeDate>
        {dateFormat(entry.time, 'MM/DD/YYYY HH:mm')}
      </ColumnTradeDate>
      <ColumnAccountName>
        <AccountName entry={entry} />
      </ColumnAccountName>
      <ColumnComments>
        <Comments>{entry.description}</Comments>
      </ColumnComments>
      <ColumnAmount>
        <LedgerValue value={entry.amount} showUSD={showUSD} />
      </ColumnAmount>
      <ColumnAmount>
        <LedgerValue value={entry.balance} showUSD={showUSD} />
      </ColumnAmount>
      <ColumnCommands>
        {/*<IconButton onClick={() => onEditEntry(entry)}>
              <PencilIcon size="medium" />
              </IconButton>*/}
      </ColumnCommands>
    </Root>
  );
};

class Entry extends Component {
  shouldComponentUpdate(nextProps) {
    const { editEntryId } = this.props;

    if (nextProps.entry.id !== this.props.entry.id) {
      return true;
    }
    if (nextProps.entry._updated_at !== this.props.entry._updated_at) {
      return true;
    }

    if (this.props.entry.id === editEntryId) {
      return true;
    }
    if (this.props.entry.id === nextProps.editEntryId) {
      return true;
    }
    if (this.props.showUSDid !== nextProps.showUSD) {
      return true;
    }

    return false;
  }

  render() {
    const {
      entry,
      index,
      showUSD
      /*onEditEntry,
      onSaveEntry,
      editEntryId,*/
    } = this.props;

    /*
    if (editEntryId === entry.id) {
      return (
        <EntryForm
          initialValues={{
            ...entry,
            tradeDate: dateFormat(entry.tradeDate, 'YY/MM/DD HH:MM')
          }}
          onSubmit={onSaveEntry}
          onCancel={() => onEditEntry(null)}
        />
      );
    }*/
    return <EntryRow index={index} entry={entry} showUSD={showUSD} />;
  }
}
export default Entry;
