import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { ClusterLink } from 'components/cluster';
import { AddressLink } from './link';
import Commands from './commands';
import { Tags } from 'components/tags';
import CreditDebitSummary from './credit-debit-summary';

const Root = styled.div`
  display: flex;
`;
const Info = styled.div`
  flex-basis: 100%;
`;
const ClusterHash = styled.div`
  color: orange;
  padding: 5px;
  font-size: smaller;
`;

const ShortAddress = styled.div`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Analysis = ({ analysis }) => {
  if (!analysis) return null;

  return (
    <div>
      {analysis.clusterHash && (
        <ClusterHash>
          <ClusterLink hash={analysis.clusterHash}>
            {analysis.clusterHash}
          </ClusterLink>
        </ClusterHash>
      )}
      <CreditDebitSummary address={analysis} />
    </div>
  );
};

export default ({ address, onSaveTag }) => {
  return (
    <Root>
      <Info>
        <AddressLink hash={address.address}>
          {' '}
          <ShortAddress>{address.address}</ShortAddress>
        </AddressLink>
        <Tags tags={_.get(address, 'meta.tags')} />
        <Analysis analysis={address.analysis} />
      </Info>
      <Commands address={address} onSaveTag={onSaveTag} />
    </Root>
  );
};
