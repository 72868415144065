import { createStore, applyMiddleware, compose } from 'redux';
import { createAction } from 'redux-actions';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import rootReducer from './modules';
import { apiMiddleware } from 'util/api';
export const history = createHistory();

export const resetLogin = createAction('LOGIN_RESET', null, () => ({
  analytics: {
    event: 'user__logged-out'
  }
}));

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
  apiMiddleware({ logoutAction: resetLogin })
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);

export default store;
