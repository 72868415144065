import { handleActions } from 'redux-actions';
import { login, logout } from './actions';
import { resetLogin } from '../../store';
import { getStoredToken, storeToken, clearStoredToken } from './token';
import jwtDecode from 'jwt-decode';

const getInitialState = () => {
  const token = getStoredToken();
  let user;
  if (token) {
    user = jwtDecode(token);
  }
  return {
    isAuthenticated: !!token,
    accessToken: token,
    user
  };
};
const authReducer = handleActions(
  {
    [resetLogin]: state => {
      clearStoredToken();
      return {
        ...state,
        isAuthenticated: false
      };
    },
    [logout]: state => {
      clearStoredToken();
      return {
        ...state,
        isAuthenticated: false
      };
    },
    [login.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [login.SUCCESS_TYPE]: (state, action) => {
      storeToken(action.payload.token);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        accessToken: action.payload.token,
        user: jwtDecode(action.payload.token)
      };
    },
    [login.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false,
      accessToken: null
    })
  },
  getInitialState()
);

export default authReducer;
