// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled.span``;
const SimpleLink = styled(Link)`
  text-decoration: none;
`;

type Props = {
  children?: React$Node,
  hash?: ?string
};

export class AddressLink extends Component<Props> {
  render() {
    const { hash, children } = this.props;

    return (
      <Root title={hash}>
        <SimpleLink to={`/address/${hash}`}>{children}</SimpleLink>
      </Root>
    );
  }
}

export const TaggedAddressLink = ({ tag }) => (
  <React.Fragment>
    {tag && <AddressLink hash={tag.hash}>{tag.name}</AddressLink>}
  </React.Fragment>
);

export default AddressLink;
