import _ from 'lodash';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import LoginForm from './form';
import { login } from './actions';
import * as Styles from 'components/styles';

const Root = styled.div`
  background: #fcfcfe;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
`;
const Logo = styled.div`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid ${Styles.BORDER_COLOR};
  display: flex;
  line-height: 1;
  padding: 12px 0;
  justify-content: center;
`;
const Version = styled.span`
  font-size: small;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;

const FormWrapper = styled.div`
  border-radius: 4px;
  border-style: solid;
  border-color: #008d00;
  padding: 40px;
  width: 400px;
`;

const StyledTitleLink = styled(Link)`
  font-size: larger;
  color: #aaaaaa;
  text-decoration: none;
`;
const LogoImage = styled.div``;
const WarningMessage = styled.div`
  color: red;
`;

class LoginContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  state = {
    error: null,
    referrer: null,
    redirect: false
  };

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState(() => ({ referrer: values.referrer }));
  }

  handleLogin = async formData => {
    const { dispatchLogin } = this.props;
    this.setState(() => ({ error: '' }));
    const result = await dispatchLogin(formData);
    if (result.error) {
      this.setState(() => ({ error: result.payload.message }));
      return;
    }
    this.context.showSuccessNotification(`${formData.email} logged in`);
    this.setState(() => ({ redirect: true }));
  };

  render() {
    const { version } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.referrer || '/'} />;
    }

    return (
      <Root>
        <Logo>
          <StyledTitleLink to="/">
            txLedger<Version>- {version}</Version>
          </StyledTitleLink>
          <LogoImage />
        </Logo>
        <Wrapper>
          <FormWrapper>
            <h2>Log in:</h2>
            <WarningMessage>{this.state.error}</WarningMessage>
            <LoginForm onSubmit={this.handleLogin} />
          </FormWrapper>
        </Wrapper>
      </Root>
    );
  }
}

const mapStateToProps = ({ user }) => ({});

const mapDispatchToProps = {
  dispatchLogin: login
};

const compose = _.flow(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);

export default compose(LoginContainer);
