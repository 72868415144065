import _ from 'lodash';

const READYSTATE_DONE = 4;

const uploadPhase = {
  begin: 'begin',
  uploading: 'uploading',
  end: 'end',
};

const fillProgressEvent = (phase, referenceData, e) => {
  return {
    referenceData,
    phase,
    deterministic: e.lengthComputable,
    loaded: e.loaded,
    total: e.total,
    percent: e.lengthComputable && e.total
      ? Math.round(e.loaded / e.total * 100)
      : -1,
  };
};

const fetchXHR = (
  endpoint,
  { method, headers, body, referenceData, onProgress },
) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.open(method, endpoint, true);

    _.forOwn(headers, (val, key) => {
      request.setRequestHeader(key, val);
    });

    request.onreadystatechange = () => {
      if (request.readyState === READYSTATE_DONE) {
        if (request.status === 0) {
          // don't resolve on this as it's a network error .. let the onerror handle rejecting
          return;
        }
        if (request.status === 200) {
          request.ok = true;
        }
        request.json = () => {
          let json;

          if (request.responseText && request.responseText.length > 0) {
            json = JSON.parse(request.responseText);
          } else {
            // this is a network error , this could be do to a number of things including
            // malformed security, network connections, file upload too large
            if (request.status === 0) {
              json = {
                meta: {
                  detail: 'A network error has occurred.',
                },
              };
            }
          }
          return json;
        };
        resolve(request);
      }
    };
    // this error happens when a network level problem.. like file too large or something
    // this will also happen if someone messes with the security token
    request.onerror = () => {
      reject();
    };

    request.upload.addEventListener(
      'loadstart',
      e => {
        if (onProgress) {
          onProgress(fillProgressEvent(uploadPhase.begin, referenceData, e));
        }
      },
      false,
    );

    request.upload.addEventListener(
      'progress',
      e => {
        if (onProgress) {
          onProgress(
            fillProgressEvent(uploadPhase.uploading, referenceData, e),
          );
        }
      },
      false,
    );

    request.upload.addEventListener(
      'load',
      e => {
        if (onProgress) {
          onProgress(fillProgressEvent(uploadPhase.end, referenceData, e));
        }
      },
      false,
    );

    // this is special client side upload fail
    //request.upload.addEventListener('error',(e) =>{
    //},false);

    request.send(body);
  });

export default fetchXHR;
