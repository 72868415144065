import React, { Component } from "react";
import styled from "styled-components";

const Panel = styled.div`
  overflow: hidden;
`;

//color: #ffffff;
const PanelTitle = styled.span`
  color: inherit;
  color: ${props => props.color};
  font-weight: bold;
`;
const PanelDescription = styled.div`
  color: inherit;
  float: right;
`;
const CloseBox = styled.div`
  padding: 0px 3px 0px 3px;
  margin: 0px 5px 0px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  float: right;
`;
const PanelContents = styled.div`
  padding: ${props => (props.hidden ? "0px" : "8px")};
  border-style: solid;
  border-width: 0px 1px 0px 1px;
  border-color: ${props => props.background};
`;

//background: ${(props)=>props.background};
const PanelTopBar = styled.div`
  background: inherit;
  border-style: solid;
  border-width: 3px 1px 2px 1px;
  border-color: ${props => props.background};
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 10px 5px;
  cursor: pointer;
`;
const PanelBottomBar = styled.div`
  background: ${props => props.background};
  width: 100%;
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  border-color: ${props => props.background};
  margin: 0px 0px 0px 0px;
  padding: 0px;
`;

export default class CollapsePanel extends Component {
  state = {
    open: false
  };

  componentWillMount() {
    const { openOnMount } = this.props;

    this.setState(() => ({ open: !!openOnMount }));
  }

  togglePanel = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  openPanel = () => {
    this.setState(() => ({ open: true }));
  };
  hidePanel = () => {
    this.setState(() => ({ open: false }));
  };

  close() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
    this.hidePanel();
  }

  open() {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }
    this.openPanel();
  }

  renderOpenedTopBar() {
    const { title, description, background } = this.props;
    return (
      <PanelTopBar background={background} onClick={this.close.bind(this)}>
        <PanelTitle color={background}>{title}</PanelTitle>
        <PanelDescription>
          {description}
          <CloseBox>X</CloseBox>
        </PanelDescription>
      </PanelTopBar>
    );
  }

  renderClosedTopBar() {
    const { title, description, background } = this.props;
    return (
      <PanelTopBar background={background} onClick={this.open.bind(this)}>
        <PanelTitle color={background}>{title}</PanelTitle>
        <PanelDescription>{description}</PanelDescription>
      </PanelTopBar>
    );
  }

  renderBottomBar() {
    const { background } = this.props;
    return <PanelBottomBar background={background} />;
  }

  renderChildren() {
    const { children } = this.props;
    return (
      <div>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            //hidePanel: this.hidePanel.bind(this),
          });
        })}
      </div>
    );
  }
  render() {
    const { background } = this.props;
    return (
      <Panel>
        {this.state.open
          ? this.renderOpenedTopBar()
          : this.renderClosedTopBar()}
        <PanelContents
          hidden={!this.state.open}
          hidePanel={this.hidePanel.bind(this)}
          background={background}
        >
          {this.state.open ? this.renderChildren() : ""}
        </PanelContents>
        {this.state.open && this.renderBottomBar()}
      </Panel>
    );
  }
}
CollapsePanel.defaultProps = {
  background: "#4040a0"
};
