import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PrivateRoute } from 'containers/auth/private-route';
import { queryAddrMeta, queryTxMeta } from 'components/with-meta/actions';
import SearchForm from 'components/search-form';
import TaggedAddresses from './tagged-addresses';
import TaggedTxs from './tagged-txs';
import AddressAnalysis from './address-analysis';
import TxAnalysis from './tx-analysis';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const AddressList = styled.div`
  flex: 0 0 20%;
`;
const AnalysisRoot = styled.div`
  flex: 0 0 80%;
  padding-left: 10px;
`;
const Root = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  width: 95%;
`;

const TaggedAddressesWrapper = styled.div`
  margin-top: 10px;
`;

const AddressChooser = ({ onSearch, taggedAddresses, taggedTxs }) => {
  return (
    <AddressList>
      <SearchForm onSubmit={values => onSearch({ text: values.searchText })} />
      <Tabs>
        <TabList>
          <Tab>Addresses</Tab>
          <Tab>Txs</Tab>
        </TabList>

        <TabPanel>
          <TaggedAddressesWrapper>
            <TaggedAddresses tags={taggedAddresses} />
          </TaggedAddressesWrapper>
        </TabPanel>
        <TabPanel>
          <TaggedAddressesWrapper>
            <TaggedTxs tags={taggedTxs} />
          </TaggedAddressesWrapper>
        </TabPanel>
      </Tabs>
    </AddressList>
  );
};

class VisualizerContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  async componentWillMount() {
    const { dispatchQueryAddrMeta, dispatchQueryTxMeta } = this.props;
    let result = dispatchQueryAddrMeta();
    if (!result.error) {
      result = dispatchQueryTxMeta();
    }
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to load tags. : ${result.payload.message}.`
      );
      return;
    }
  }

  handleSearch = async obj => {
    let val = obj.text.trim();

    let result = val.match(/^[13][a-km-zA-HJ-NP-Z0-9]{26,35}$/);
    // this doesn't account for bech32
    // also if we want to truly validate then we should do a
    //try {
    //  bs58check.decode(address);
    //
    //} catch (e) {
    //  console.log(`${address} decode failed : ${e.message}`);
    //}

    if (result) {
      this.props.history.push(`/address/${val}`);
    } else {
      this.props.history.push(`/tx/${val}`);
    }
  };

  render() {
    const { auth } = this.props;
    return (
      <Root>
        <AddressChooser {...this.props} onSearch={this.handleSearch} />
        <AnalysisRoot>
          <PrivateRoute
            auth={auth}
            path="/address/:id"
            component={AddressAnalysis}
          />
          <PrivateRoute auth={auth} path="/tx/:id" component={TxAnalysis} />
        </AnalysisRoot>
      </Root>
    );
  }
}

const mapStateToProps = ({ visualizer, auth }) => ({
  taggedAddresses: visualizer.meta.addrList,
  taggedTxs: visualizer.meta.txList,
  auth
});

const mapDispatchToProps = {
  dispatchQueryAddrMeta: queryAddrMeta,
  dispatchQueryTxMeta: queryTxMeta
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisualizerContainer)
);
