// @flow
import React from 'react';

export type ErrorInfo = {
  componentStack: string
};

export type ErrorHandler = (error: Error, info: ?ErrorInfo) => mixed;
export type ErrorRenderer = (error: Error, info: ?ErrorInfo) => React$Node;

type Props = {
  children: React$Node,
  onError?: ErrorHandler,
  renderError: ErrorRenderer
};

type State = {
  error: ?Error,
  errorInfo: ?ErrorInfo
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(() => ({ error, errorInfo }));

    if (typeof this.props.onError === 'function') {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.error) {
      return this.props.renderError(this.state.error, this.state.errorInfo);
    }

    return this.props.children;
  }
}
