import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { fetchTx } from './actions';
import { updateTxMeta, addTxMeta } from 'components/with-meta/actions';

const updatePutsFromMeta = tx => {
  if (tx.meta) {
    _.each(tx.meta.outputs, outputMeta => {
      if (!tx.outputs[outputMeta.index].meta) {
        tx.outputs[outputMeta.index].meta = { tags: [] };
      }
      tx.outputs[outputMeta.index].meta.tags = [
        ...tx.outputs[outputMeta.index].meta.tags,
        ...outputMeta.tags
      ];
      tx.outputs[outputMeta.index].meta.isChange = outputMeta.isChange;
    });
  }
  return tx;
};
const analysisTxReducer = handleActions(
  {
    [fetchTx.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchTx.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoading: false,
      item: updatePutsFromMeta(action.payload)
    }),
    [fetchTx.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    }),
    [updateTxMeta.SUCCESS_TYPE]: (state, action) => {
      if (!state.item || state.item.hash !== action.payload.hash) {
        return state;
      }
      return {
        ...state,
        item: {
          ...state.item,
          meta: action.payload
        }
      };
    },
    [addTxMeta.SUCCESS_TYPE]: (state, action) => {
      if (!state.item || state.item.hash !== action.payload.hash) {
        return state;
      }
      return {
        ...state,
        item: {
          ...state.item,
          meta: action.payload
        }
      };
    }
  },
  { isLoading: false }
);

export default analysisTxReducer;
