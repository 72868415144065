import { handleActions } from 'redux-actions';
import { fetchBlock } from './actions';

const blockReducer = handleActions(
  {
    [fetchBlock.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchBlock.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoading: false,
      block: action.payload
    }),
    [fetchBlock.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    })
  },
  {
    isLoading: false,
    block: null
  }
);

export default blockReducer;
