import { createAPIAction } from 'util/api';

/*
export const fetchAddress =  createAPIAction('visualizer_address', {
    endpoint:'/addr',
    method: 'POST',
    body: options => {
      return JSON.stringify(options);
    },
  });*/

export const fetchAddress = createAPIAction('visualizer_fetch_address', {
  endpoint: ({ id, txDetail }) =>
    `/addr/${id}?txDetail=${txDetail ? 'true' : 'false'}`
});

export const addAddress = createAPIAction('visualizer_add_address', {
  endpoint: ({ id, txDetail }) =>
    `/addr/${id}?txDetail=${txDetail ? 'true' : 'false'}`
});
