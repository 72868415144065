import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { addAddress, fetchAddress } from './actions';
import { updateAddrMeta, addAddrMeta } from 'components/with-meta/actions';

// THIS IS A COPY AND PASTE FROM TX REDUCER

const updatePutsFromMeta = tx => {
  if (tx.meta) {
    _.each(tx.meta.outputs, outputMeta => {
      if (!tx.outputs[outputMeta.index].meta) {
        tx.outputs[outputMeta.index].meta = { tags: [] };
      }
      tx.outputs[outputMeta.index].meta.tags = [
        ...tx.outputs[outputMeta.index].meta.tags,
        ...outputMeta.tags
      ];
      tx.outputs[outputMeta.index].meta.isChange = outputMeta.isChange;
    });
  }
  return tx;
};

const updateTxPuts = txs => {
  _.each(txs, tx => updatePutsFromMeta(tx));
};

//  compute a list of unique transaction times for all transactions
// in a list
const computeTxTimes = times => {
  // compute the sorted tx list for date selection
  let list = times ? [...times] : [];

  list = list.sort((a, b) => a - b);

  let txTimes = { list: [] };

  // collapse any that are the same from the list
  // and only put in times
  let n,
    last = '';
  _.each(list, time => {
    n = time;
    if (n !== last) {
      txTimes.list.push(time);
      last = n;
    }
  });

  if (txTimes.list.length > 0) {
    txTimes.first = txTimes.list[0];
    txTimes.last = txTimes.list[txTimes.list.length - 1];
  }

  return txTimes;
};

const analysisChainReducer = handleActions(
  {
    [addAddress.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),
    [addAddress.SUCCESS_TYPE]: (state, action) => {
      updateTxPuts(action.payload.txs);
      return {
        ...state,
        isLoading: false,
        interestingAddresses: _.find(
          state.interestingAddresses,
          x => x === action.payload.address
        )
          ? state.interestingAddresses
          : [...state.interestingAddresses, action.payload.address],
        txs: [...state.txs, ...action.payload.txs],
        txTimes: action.payload.analysis
          ? computeTxTimes([
              ...state.txTimes.list,
              ..._.map(action.payload.txs, tx => tx.block.time)
            ])
          : state.txTimes
      };
    },
    [addAddress.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    }),
    [fetchAddress.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchAddress.SUCCESS_TYPE]: (state, action) => {
      updateTxPuts(action.payload.txs);
      return {
        ...state,
        isLoading: false,
        primaryAddress: action.payload,
        txs: action.payload.txs,
        txTimes: action.payload.analysis
          ? computeTxTimes(_.map(action.payload.txs, tx => tx.block.time))
          : { list: [] }
      };
    },
    [fetchAddress.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    }),
    [updateAddrMeta.SUCCESS_TYPE]: (state, action) => {
      if (
        !state.primaryAddress ||
        state.primaryAddress.address !== action.payload.hash
      ) {
        return state;
      }
      return {
        ...state,
        primaryAddress: {
          ...state.primaryAddress,
          meta: action.payload
        }
      };
    },
    [addAddrMeta.SUCCESS_TYPE]: (state, action) => {
      if (
        !state.primaryAddress ||
        state.primaryAddress.address !== action.payload.hash
      ) {
        return state;
      }
      return {
        ...state,
        primaryAddress: {
          ...state.primaryAddress,
          meta: action.payload
        }
      };
    }
  },
  { isLoading: false, interestingAddresses: [] }
);

export default analysisChainReducer;
