import _ from 'lodash';
import { format } from 'd3-format';
import { format as dateFormat } from 'date-fns';
export const SATOSHIS = 100000000;

export const fromSatoshis = input => (isNaN(input) ? 0 : input / SATOSHIS);

export const blockTimetoDate = input => new Date(input * 1000);
export const fromBlockTime = time => new Date(time * 1000);

export const formatBitcoinAmount = amount =>
  amount === null || amount === undefined ? '' : format(',.3f')(amount);

export const formatSatoshisAsBitcoin = amount =>
  formatBitcoinAmount(fromSatoshis(amount));

export const formatDateTime = d => dateFormat(d, 'MM/DD/YYYY HH:mm:ss');
export const formatBlockDateTime = d => formatDateTime(blockTimetoDate(d));
const isAddressInCollection = (collection, address) =>
  _.find(collection, item => item.address === address);

export const isCredit = (tx, address) => {
  if (typeof address === 'object') {
    address = address.address;
  }
  return isAddressInCollection(tx.outputs, address);
};

export const isDebit = (tx, address) => {
  if (typeof address === 'object') {
    address = address.address;
  }
  // if we're one of the inputs to a transaction
  // then we're a debit from the address
  return isAddressInCollection(tx.inputs, address);
};

export const isChangeOutput = output =>
  _.get(output, 'analysis.change', false) ||
  _.get(output, 'meta.isChange', false);
