import React from 'react';
import styled from 'styled-components';
import TxSummary from './summary';
import { Button } from 'components/button';
import AddTag from 'components/add-tag';

const Root = styled.div`
  display: flex;
`;
const Commands = styled.div`
  display: flex;
`;
const Info = styled.div`
  flex-basis: 100%;
`;

export default ({ tx, onCluster, onSaveTag, onDeleteTag }) => {
  return (
    <Root>
      <Info>
        <TxSummary tx={tx} onDeleteTag={onDeleteTag} />
      </Info>
      <Commands>
        {onSaveTag && (
          <AddTag
            initialValues={{ tagText: '' }}
            onSaveTag={data => onSaveTag({ tx, tagText: data.tagText })}
            description={<div>Tx Tag</div>}
          />
        )}
        {tx.inputs.length > 1 && (
          <Button
            size="small"
            buttonType="primary"
            onClick={() => onCluster(tx)}
          >
            Cluster
          </Button>
        )}
      </Commands>
    </Root>
  );
};
