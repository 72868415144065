import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { addAddress, fetchAddress } from './actions';
import { clusterTx, clusterAddresses } from '../actions';
import { AddressDetail, CreditDebitSummary } from 'components/address';
import { AddressLedger } from 'components/ledger';
import { setTimeout } from 'timers';
import { NetworkGraph } from 'components/cytoscape';
import CollapsePanel from 'components/collapse-panel';
import FocusItem from 'components/focus-item';
import { TxList } from 'components/tx';
import { TimeRange } from 'components/tx-times';
import withMeta from 'components/with-meta';

const AnalysisRoot = styled.div``;
const Root = styled.div`
  margin: 0px;
  width: 100%;
`;

const RightCommands = styled.div`
  float: right;
`;

const GraphAnalysis = ({
  analysisChain,
  onChangeHidden,
  showHidden,
  onChangeLocked,
  isLocked,
  txTimes,
  analysisTimeStart,
  analysisTimeEnd,
  onChangeAnalysisTimeStart,
  onChangeAnalysisTimeEnd,
  onClusterAddresses,
  onChangeFocusItem,
  onChangeChange,
  focusItem,
  onClusterTx,
  onSaveTag,
  onSaveGraph,
  onRestoreGraph,
  onAddAddress
}) => {
  return (
    <React.Fragment>
      <RightCommands>
        <span>Hidden Items</span>
        <input type="checkbox" onChange={onChangeHidden} checked={showHidden} />
        <span>Lock</span>
        <input type="checkbox" onChange={onChangeLocked} checked={isLocked} />
      </RightCommands>
      <TimeRange
        txTimes={txTimes}
        start={analysisTimeStart}
        end={analysisTimeEnd}
        onChangeStart={onChangeAnalysisTimeStart}
        onChangeEnd={onChangeAnalysisTimeEnd}
      />
      <NetworkGraph
        network={{
          txs: analysisChain.txs,
          primaryAddress: analysisChain.primaryAddress,
          interestingAddresses: analysisChain.interestingAddresses
        }}
        startTime={analysisTimeStart}
        endTime={analysisTimeEnd}
        showHidden={showHidden}
        isLocked={isLocked}
        onCluster={onClusterAddresses}
        onAddAddress={onAddAddress}
        onSaveGraph={onSaveGraph}
        onRestoreGraph={onRestoreGraph}
        onItemClick={({ value }) => {
          onChangeFocusItem(value);
        }}
      />
      <FocusItem
        item={focusItem}
        onCluster={onClusterTx}
        onSaveTag={onSaveTag}
        onChangeChange={onChangeChange}
      />
    </React.Fragment>
  );
};

const ChainAnalysis = ({
  analysisChain,
  txTimes,
  analysisTimeStart,
  analysisTimeEnd,
  focusItem,
  showHidden,
  onDeleteAddressTag,
  onSaveTag,
  onChangeAnalysisTimeStart,
  onChangeAnalysisTimeEnd,
  onChangeFocusItem,
  onChangeChange,
  onChangeHidden,
  onClusterTx,
  onClusterAddresses,
  onSaveGraph,
  onRestoreGraph,
  onAddAddress,
  onChangeLocked,
  isLocked
}) => {
  return (
    <AnalysisRoot>
      <AddressDetail
        address={analysisChain ? analysisChain.primaryAddress : null}
        onDeleteTag={onDeleteAddressTag}
        onSaveTag={onSaveTag}
      />
      <CreditDebitSummary address={analysisChain.primaryAddress} />
      <CollapsePanel
        title="Transaction Detail"
        description={_.get(analysisChain, 'txs.length', 0)}
      >
        {analysisChain.txs ? (
          <TxList
            txs={analysisChain.txs}
            onCluster={onClusterTx}
            onSaveTag={onSaveTag}
          />
        ) : (
          <div> Analysis is Disabled </div>
        )}
      </CollapsePanel>
      <CollapsePanel title="Graph">
        {analysisChain.primaryAddress ? (
          <GraphAnalysis
            analysisChain={analysisChain}
            onChangeHidden={onChangeHidden}
            showHidden={showHidden}
            onChangeLocked={onChangeLocked}
            isLocked={isLocked}
            txTimes={txTimes}
            analysisTimeStart={analysisTimeStart}
            analysisTimeEnd={analysisTimeEnd}
            onChangeAnalysisTimeStart={onChangeAnalysisTimeStart}
            onChangeAnalysisTimeEnd={onChangeAnalysisTimeEnd}
            onClusterAddresses={onClusterAddresses}
            onChangeFocusItem={onChangeFocusItem}
            onChangeChange={onChangeChange}
            focusItem={focusItem}
            onClusterTx={onClusterTx}
            onSaveTag={onSaveTag}
            onSaveGraph={onSaveGraph}
            onRestoreGraph={onRestoreGraph}
            onAddAddress={onAddAddress}
          />
        ) : (
          <div> Analysis is Disabled </div>
        )}
      </CollapsePanel>
      <CollapsePanel title="Ledger">
        <AddressLedger
          address={analysisChain ? analysisChain.primaryAddress : null}
        />
      </CollapsePanel>
    </AnalysisRoot>
  );
};

class ChainAnalysisContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  state = {
    analysisTimeStart: 0,
    analysisTimeEnd: 0,
    focusItem: null,
    showHidden: true,
    isLocked: false
  };

  fetchAddress = async obj => {
    const { dispatchFetchAddress } = this.props;
    const result = await dispatchFetchAddress({ ...obj, txDetail: true });

    if (result.error) {
      this.context.showErrorNotification(
        `Failed to retrieve address. : ${result.payload.message}.`
      );
      return;
    }

    setTimeout(() => {
      this.setState({ analysisTimeStart: this.props.txTimes.first });
      this.setState({ analysisTimeEnd: this.props.txTimes.last });
      this.setState({ focusItem: null });
    }, 1);
  };

  // this should happen outside of here in containing component
  async componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;
    const {
      match: { params: prevParams }
    } = prevProps;

    if (!prevParams || prevParams.id !== params.id) {
      this.fetchAddress({ id: params.id });
    }
  }

  // doing this cause otherwise we don't load if we hit refresh
  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchAddress({ id: params.id });
  }

  handleAddAddress = async params => {
    const { dispatchAddAddress } = this.props;

    const result = await dispatchAddAddress({
      id: params.addresses[0].address
    });

    if (result.error) {
      this.context.showErrorNotification(
        `Failed to retrieve address. : ${result.payload.message}.`
      );
      return;
    }

    setTimeout(() => {
      this.setState({
        analysisTimeStart: this.props.txTimes.first,
        analysisTimeEnd: this.props.txTimes.last
      });
    }, 1);
  };

  handleClusterTx = async tx => {
    const { dispatchClusterTx } = this.props;
    const result = await dispatchClusterTx({ id: tx.hash });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to cluster tx. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification(`tx ${tx.hash} clustered `);
  };

  handleClusterAddresses = async selected => {
    const { dispatchClusterAddresses } = this.props;
    const result = await dispatchClusterAddresses({
      addresses: selected.addresses
    });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to cluster addresses. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification('clustered');
  };

  exportToCsv = json => {
    //console.log(json);
    const lines = [];
    const shapeMap = {};
    let id = 1;
    lines.push(
      'Id,Name,Shape Library,Page ID,Contained By,Group,Line Source,Line Destination,Source Arrow,Destination Arrow,Text Area 1'
    );
    lines.push(`${id},Page,,,,,,,,,Page 1,,`);
    id++;
    _.each(json.elements.nodes, node => {
      if (node.data.type === 'addr') {
        lines.push(`${id},Circle,Geometric Shapes,1,,,,,,,${node.data.name},,`);
        shapeMap[node.data.address] = id;
        id++;
      }
      if (node.data.type === 'tx') {
        lines.push(
          `${id},Rectangle,Geometric Shapes,1,,,,,,,${node.data.name},,`
        );
        shapeMap[node.data.id] = id;
        id++;
      }
    });
    _.each(json.elements.edges, edge => {
      const srcId = shapeMap[edge.data.source];
      const dstId = shapeMap[edge.data.target];
      lines.push(
        `${id},Line,,1,,,${srcId},${dstId},None,Arrow,${edge.data.name},,`
      );
      id++;
    });
    console.log(
      _.reduce(lines, (acc, line) => (acc = `${acc}\r\n${line}`), '')
    );
    //_.each(lines, line => console.log(line));
    //console.log(json);
  };

  handleSaveGraph = async json => {
    this.exportToCsv(json);
    this.state.savedGraph = json;
  };

  handleRestoreGraph = () => {
    return this.state.savedGraph;
  };

  render() {
    const {
      analysisChain,
      txTimes,
      onChangeChange,
      onSaveTag,
      onDeleteAddressTag
    } = this.props;

    return (
      <div>
        <Root>
          {analysisChain && analysisChain.primaryAddress && (
            <ChainAnalysis
              analysisChain={analysisChain}
              txTimes={txTimes}
              analysisTimeStart={this.state.analysisTimeStart}
              analysisTimeEnd={this.state.analysisTimeEnd}
              focusItem={this.state.focusItem}
              showHidden={this.state.showHidden}
              isLocked={this.state.isLocked}
              onChangeAnalysisTimeStart={value =>
                this.setState({ analysisTimeStart: value })
              }
              onChangeAnalysisTimeEnd={value =>
                this.setState({ analysisTimeEnd: value })
              }
              onSaveTag={onSaveTag}
              onDeleteAddressTag={id =>
                onDeleteAddressTag(analysisChain.primaryAddress, id)
              }
              onChangeFocusItem={value => {
                if (this.state.focusItem && !this.state.isLocked) {
                  if (this.state.focusItem.id === value.id) {
                    if (this.state.focusItem.type === 'addr') {
                      //return this.props.history.push(`/address/${value.id}`);
                      return this.handleAddAddress({
                        addresses: [{ address: value.id }]
                      });
                    }
                    if (this.state.focusItem.type === 'tx') {
                      return this.props.history.push(`/tx/${value.id}`);
                    }
                  }
                }
                this.setState(() => ({ focusItem: value }));
              }}
              onChangeChange={onChangeChange}
              onChangeHidden={() =>
                this.setState(prevState => ({
                  showHidden: !prevState.showHidden
                }))
              }
              onChangeLocked={() =>
                this.setState(prevState => ({
                  isLocked: !prevState.isLocked
                }))
              }
              onClusterTx={this.handleClusterTx}
              onClusterAddresses={this.handleClusterAddresses}
              onSaveGraph={this.handleSaveGraph}
              onRestoreGraph={this.handleRestoreGraph}
              onAddAddress={this.handleAddAddress}
            />
          )}
        </Root>
      </div>
    );
  }
}

const mapStateToProps = ({ visualizer }) => ({
  analysisChain: visualizer.analysisChain,
  txTimes: visualizer.analysisChain.txTimes
});

const mapDispatchToProps = {
  dispatchFetchAddress: fetchAddress,
  dispatchAddAddress: addAddress,
  dispatchClusterTx: clusterTx,
  dispatchClusterAddresses: clusterAddresses
};

const compose = _.flow(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withMeta
);

export default compose(ChainAnalysisContainer);
