import { css } from 'styled-components';
import { darken, lighten } from 'polished';
import * as Styles from 'components/styles';

export const getSizingProps = size => {
  let fontSize = '0.875rem';
  let height = '40px';
  let padding= '0 16px';

  switch (size) {
    case 'mini':
      fontSize = '0.500rem';
      height = '20px';
      padding = '0 2px';
      break;

    case 'small':
      fontSize = '0.750rem';
      height = '30px';
      break;

    case 'large':
      fontSize = '1rem';
      height = '52px';
      break;
    default:
        break;
  }

  return `
    font-size: ${fontSize};
    height: ${height};
    padding: ${padding};
  `;
};

export const buttonType = (background, color) => {
  const darker = darken(0.05, background);
  const lighter = lighten(0.05, background);

  return css`
    background: ${background};
    border-color: ${darker};
    color: ${color};

    &:focus {
      ${Styles.focus(background, background)};
    }

    &:hover {
      background: ${lighter};
      border-color: ${background};
    }

    ${p =>
      p.disabled &&
      css`
        &,
        &:hover {
          background: #e0e5ee;
          border-color: #dbe0e9;
        }
      `} ${p =>
        p.alt &&
        css`
          background: transparent;
          border-color: ${background};
          color: ${background};

          &:hover {
            background: ${background};
            color: ${color};
            border-color: ${lighter};
          }
        `};
  `;
};