// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import * as Styles from '../styles';
import { Button } from '../button';

import type { ErrorInfo } from './error-boundary';

type Props = {
  error: Error,
  errorInfo: ?ErrorInfo
};

type State = {
  showStack: boolean
};

export default class TopLevelError extends Component<Props, State> {
  state = {
    showStack: false
  };

  handleRefresh = (event: Event) => {
    event.preventDefault();
    window.location.reload(true);
  };

  handleToggleStack = () =>
    this.setState(({ showStack }) => ({ showStack: !showStack }));

  render() {
    let stack;

    const { error, errorInfo } = this.props;

    if (errorInfo && errorInfo.componentStack) {
      stack = (
        <Stack>
          <Button size="small" onClick={this.handleToggleStack}>
            {this.state.showStack ? 'Hide' : 'Show'} Error Details
          </Button>

          {this.state.showStack && (
            <StackDetails>
              <h2>Error: {error.message}</h2>
              <pre>{errorInfo.componentStack}</pre>
            </StackDetails>
          )}
        </Stack>
      );
    }

    return (
      <Root>
        <SadFace>:(</SadFace>
        <h1>Uh oh, looks like an error occurred!</h1>

        <p>
          We’d like to investigate but you have to let us know. Please{' '}
          <span onClick={this.handleRefresh}>refresh</span> and try again, or
          just let us know in slack{' '}
          <a href="https://inhub.slack.com/messages/lskjflsdfj/">
            Some Slack Channel
          </a>
          .
        </p>

        {stack}
      </Root>
    );
  }
}

// STYLES

const Root = styled.div`
  border: 1px solid ${Styles.BORDER_COLOR};
  border-radius: 4px;
  margin-top: 16px;
  padding: 4rem 6rem;
  background-color: #1273aa;
  color: white;

  a:link {
    color: white;
  }
  a:visited {
    color: white;
  }
  a:active {
    color: white;
  }
  h1 {
    margin: 0 0 2rem;
  }

  p {
    line-height: 1.5;
  }
`;
const SadFace = styled.div`
  font-size: 6rem;
`;

const Stack = styled.div`
  margin-top: 2rem;
`;

const StackDetails = styled.div`
  background: ${Styles.CONTENT_BACKGROUND_COLOR};
  color: ${Styles.MUTED_COLOR};
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 1rem;

  h2 {
    margin: 0;
  }

  pre {
    margin: 0;
  }
`;
