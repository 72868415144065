import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-motion-ui-pack';
import styled, { css } from 'styled-components';
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from 'components/icons';

const typeIconMap = {
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
};

const animate = {
  enter: {
    opacity: 1,
    translateY: 0,
  },
  leave: {
    opacity: 0,
    translateY: -50,
    translateX: 400,
  },
};

const DEFAULT_AUTO_DISMISS_TIMEOUT = 7 * 1000;

export default class Alert extends PureComponent {
  static propTypes = {
    autoDismiss: PropTypes.bool,
    autoDismissTimeout: PropTypes.number,
    className: PropTypes.string,
    dismissable: PropTypes.bool,
    onDismiss: PropTypes.func,
    type: PropTypes.oneOf(['success', 'error', 'info']),
  };

  static defaultProps = {
    autoDismiss: true,
    autoDismissTimeout: DEFAULT_AUTO_DISMISS_TIMEOUT,
    type: 'info',
  };
  state = {
    showDetails: false,
  };

  componentDidMount() {
    const { autoDismiss, autoDismissTimeout, onDismiss, id } = this.props;

    if (autoDismiss) {
      this._dismissTimeout = setTimeout(
        () => onDismiss({ id }),
        autoDismissTimeout,
      );
    }
  }

  handleDismiss = () => this.props.onDismiss({ id: this.props.id });

  /*renderError(props) {
    const { error, onResetError } = props;
    if (error) {
      return (
          <div>
            <small>
              status:{error.status}
            </small>
            {error.name}:
            {error.message}
          </div>
      );
    }

    return <div />;
  }*/
  showDetails = () => {
    const { stack } = this.props;

    if (stack) {
      this.setState(() => ({ showDetails: true }));
    }
  };

  renderDetails() {
    const { stack } = this.props;
    return <Details>{stack}</Details>;
  }

  render() {
    const { dismissable, message, stack, type } = this.props;

    const Icon = typeIconMap[type];

    return (
      <Transition
        component={false}
        measure={false}
        enter={animate.enter}
        leave={animate.leave}
      >
        {!!message && (
          <Root key="alert" role="alert">
            <AlertBox type={type}>
              <AlertIcon onClick={this.showDetails} clickable={!!stack}>
                <Icon />
              </AlertIcon>
              <div>
                {message}
                {this.state.showDetails && this.renderDetails()}
              </div>
              {dismissable && (
                <Dismiss onClick={this.handleDismiss}>
                  <CloseIcon />
                </Dismiss>
              )}
            </AlertBox>
          </Root>
        )}
      </Transition>
    );
  }
}

// STYLES

const Root = styled.div``;
const AlertBox = styled.div`
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.875rem;
  padding: 12px 16px;
  z-index: 2000;

  ${p =>
    p.type === 'success' &&
    css`
      background: #edfce7;
      border-color: rgba(145, 178, 125, 0.25);
      color: #7c986b;
    `} ${p =>
      p.type === 'info' &&
      css`
        background: #ffffd7;
        border: 2px solid rgba(195, 148, 62, 0.25);
        color: #c3943e;
      `} ${p =>
      p.type === 'error' &&
      css`
        background: #ffecec;
        border-color: rgba(232, 95, 113, 0.25);
        color: #e85f71;
      `};
`;

const AlertIcon = styled.div`
  align-items: center;
  display: flex;
  margin-right: 10px;
  cursor: ${props => (props.clickable ? 'pointer' : '')};

  .icon {
    height: 16px;
    width: 16px;
  }
`;

const Dismiss = styled.div`
  cursor: pointer;
  border: 1px solid;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 16px;
  padding: 0px 3px 0px 3px;
  .icon {
    height: 8px;
    width: 8px;
  }
`;
const Details = styled.div`border-top: 1px solid;`;