import _ from 'lodash';
import {
  ApiError,
  CALL_API,
  getContentTypeResponse,
  getContentTypeError,
  createActionWith,
  handleError,
  isValidAction
} from './utils';
import fetchXHR from './fetch-xhr';

const apiMiddleware = (options = {}) => {
  if (!options.logoutAction) {
    throw new Error('Missing required option: "logoutAction"');
  }

  return store => next => async action => {
    if (!isValidAction(action)) {
      return next(action);
    }

    const callAPI = action[CALL_API];

    let { endpoint, requestData } = callAPI;

    if (typeof endpoint === 'function') {
      endpoint = endpoint(store.getState());
    }

    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }

    // Small utility for creating FSAs
    const actionWith = createActionWith(action);

    const { method, body, onError, types, referenceData } = callAPI;
    const token = _.get(store.getState(), 'auth.accessToken');
    const [requestType, successType, failureType, progressType] = types;

    // Dispatch request FSA
    next(
      actionWith({
        type: requestType,
        payload: requestData
      })
    );

    const headers = {
      Authorization: `Bearer ${token}`
    };

    // if contentType isn't specified we default to json
    let contentType = _.get(callAPI, 'contentType', 'application/json');

    let res;
    let json;
    let error;
    const isFileTransfer = contentType === 'multipart/form-data';

    try {
      if (!isFileTransfer) {
        headers['Content-Type'] = contentType;
        // TODO: since fetchXHR and fetch have the same api, just merge into a single util
        res = await fetch(endpoint, { method, body, headers });
      } else {
        // if contentType is multipart/form-data we don't add a header as the browser will handle the data formatting
        // we also use XHR as we assume this is for file uploading and also set up dispatching progress information
        const handleProgress = progress => {
          next(
            actionWith({
              type: progressType,
              payload: progress
            })
          );
        };

        res = await fetchXHR(endpoint, {
          method,
          referenceData,
          body,
          headers,
          onProgress: handleProgress
        });
      }

      json = await getContentTypeResponse(contentType, res);
    } catch (e) {
      // The request was malformed, or there was a network error
      if (!e || !e.message) {
        error = 'A network error has occurred';
        if (isFileTransfer) {
          if (_.get(requestData, 'files.length', 0) > 1) {
            error +=
              ': The transfer size may be too large for the server. Try uploading files one at a time.';
          } else {
            error +=
              ': You may be uploading a file that is too large for the server.';
          }
        }
      } else {
        error = e.message;
      }
    }

    if (error || !res || !res.ok) {
      return handleError({
        dispatch: next,
        error: new ApiError(
          _.get(res, 'status', 0),
          error || getContentTypeError(contentType, json)
        ),
        failureType,
        requestData,
        onError,
        defaultOnError: options.defaultOnError,
        logoutAction: options.logoutAction
      });
    }

    return next(
      actionWith({
        meta: {
          requestData
        },
        type: successType,
        payload: json
      })
    );
  };
};

export default apiMiddleware;
