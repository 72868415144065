import React from 'react';
import { SatoshisAsBitcoin } from 'components/btc';
import { Credit, Debit, BitcoinValue } from 'components/util';

export default ({ address }) => {
  if (!address) {
    return null;
  }

  return (
    <div>
      <Credit>
        <BitcoinValue>
          <SatoshisAsBitcoin amount={address.totalReceived} />
        </BitcoinValue>
      </Credit>
      <Debit>
        <BitcoinValue>
          <SatoshisAsBitcoin amount={address.totalSent} />
        </BitcoinValue>
      </Debit>
      <BitcoinValue>
        <SatoshisAsBitcoin amount={address.balance} />
      </BitcoinValue>
      <BitcoinValue>
        {address.totalTx && <span>{address.totalTx} Transactions</span>}
      </BitcoinValue>
    </div>
  );
};
