import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addAddrMeta,
  addTxMeta,
  updateAddrMeta,
  updateTxMeta,
  fetchAddrMetaByHash,
  fetchTxMetaByHash
} from './actions';

const withMeta = WrappedComponent => {
  const mapDispatchToProps = {
    dispatchUpdateAddrMeta: updateAddrMeta,
    dispatchAddAddrMeta: addAddrMeta,
    dispatchUpdateTxMeta: updateTxMeta,
    dispatchAddTxMeta: addTxMeta,
    dispatchFetchAddrMetaByHash: fetchAddrMetaByHash,
    dispatchFetchTxMetaByHash: fetchTxMetaByHash
  };

  return connect(
    null,
    mapDispatchToProps
  )(
    class WithMeta extends Component {
      static contextTypes = {
        showSuccessNotification: PropTypes.func,
        showErrorNotification: PropTypes.func
      };

      handleDeleteAddressTag = async (address, id) => {
        const {
          dispatchUpdateAddrMeta,
          dispatchFetchAddrMetaByHash
        } = this.props;

        let result = await dispatchFetchAddrMetaByHash({
          hash: address.address
        });

        const meta = result.payload;

        meta.tags = _.filter(meta.tags, tag => tag._id !== id);

        result = await dispatchUpdateAddrMeta({ meta });

        if (result.error) {
          this.context.showErrorNotification(
            `Failed to delete tag. : ${result.payload.message}.`
          );
          return;
        }
        this.context.showSuccessNotification('tag deleted!');
      };

      handleDeleteTxTag = async (tx, id) => {
        const { dispatchUpdateTxMeta, dispatchFetchTxMetaByHash } = this.props;

        let result = await dispatchFetchTxMetaByHash({
          hash: tx.hash
        });

        const meta = result.payload;

        meta.tags = _.filter(meta.tags, tag => tag._id !== id);

        result = await dispatchUpdateTxMeta({ meta });

        if (result.error) {
          this.context.showErrorNotification(
            `Failed to delete tag. : ${result.payload.message}.`
          );
          return;
        }
        this.context.showSuccessNotification('tag deleted!');
      };

      handleAddTag = async data => {
        const { address, tx, tagText } = data;
        const {
          dispatchUpdateAddrMeta,
          dispatchAddAddrMeta,
          dispatchFetchAddrMetaByHash,
          dispatchUpdateTxMeta,
          dispatchAddTxMeta,
          dispatchFetchTxMetaByHash
        } = this.props;

        if (!tagText || tagText.length < 1) {
          return;
        }

        let meta = { tags: [{ name: tagText }] };

        let result;

        if (!address) {
          if (tx) {
            let existing = await dispatchFetchTxMetaByHash({
              hash: tx.hash
            });

            if (existing.error) {
              existing = null;
            }
            if (existing) {
              meta = existing.payload;
              meta.tags.push({ name: tagText });
              result = await dispatchUpdateTxMeta({
                meta
              });
            } else {
              meta.hash = tx.hash;
              result = await dispatchAddTxMeta({
                meta
              });
            }
          } else {
            return;
          }
        } else {
          let existing = await dispatchFetchAddrMetaByHash({
            hash: address.address
          });

          if (existing.error) {
            existing = null;
          }

          if (existing) {
            meta = existing.payload;
            meta.tags.push({ name: tagText });
            result = await dispatchUpdateAddrMeta({
              meta
            });
          } else {
            meta.hash = address.address;
            result = await dispatchAddAddrMeta({
              meta
            });
          }
        }

        if (result.error) {
          this.context.showErrorNotification(
            `Failed to add tag. : ${result.payload.message}.`
          );
          return;
        }
        this.context.showSuccessNotification(
          `tag ${tagText} added to ${meta.hash}!`
        );
      };

      handleChangeChange = async data => {
        const { tx, idx, isChange } = data;
        const {
          dispatchUpdateTxMeta,
          dispatchAddTxMeta,
          dispatchFetchTxMetaByHash
        } = this.props;
        let meta, result;

        let existing = await dispatchFetchTxMetaByHash({
          hash: tx.hash
        });

        if (existing.error) {
          existing = null;
        }

        if (existing) {
          meta = existing.payload;
          meta.outputs.push({ index: idx, isChange });
          result = await dispatchUpdateTxMeta({
            meta
          });
        } else {
          meta = {
            hash: tx.hash,
            outputs: [{ index: idx, isChange: true }]
          };
          result = await dispatchAddTxMeta({
            meta
          });
        }
        if (result.error) {
          this.context.showErrorNotification(
            `Failed to change state. : ${result.payload.message}.`
          );
          return;
        }

        this.context.showSuccessNotification(
          `change state changed on ${meta.hash}!`
        );
      };

      render() {
        return (
          <WrappedComponent
            onDeleteAddressTag={this.handleDeleteAddressTag}
            onDeleteTxTag={this.handleDeleteTxTag}
            onSaveTag={this.handleAddTag}
            onChangeChange={this.handleChangeChange}
            {...this.props}
          />
        );
      }
    }
  );
};

export default withMeta;
