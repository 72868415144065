import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchClusters } from './actions';
import CollapsePanel from 'components/collapse-panel';

const AnalysisRoot = styled.div`
  flex: 0 0 80%;
  padding-left: 10px;
`;
const Root = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  width: 95%;
`;
const Cluster = styled.div``;

const ClusterList = ({ clusters }) => {
  return (
    <div>
      {_.map(clusters, cluster => (
        <Cluster key={cluster.hash}>
          <CollapsePanel
            title={cluster.hash}
            description={cluster.addresses.length}
          >
            {_.map(cluster.addresses, (address, idx) => (
              <div key={idx}>{address}</div>
            ))}
          </CollapsePanel>
        </Cluster>
      ))}
    </div>
  );
};

class ClusterManagerContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  async componentWillMount() {
    const { dispatchFetchClusters } = this.props;

    const result = await dispatchFetchClusters();
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to load clusters. : ${result.payload.message}.`
      );
      return;
    }
  }

  render() {
    const { clusters } = this.props;
    return (
      <div>
        <Root>
          <AnalysisRoot>
            <ClusterList clusters={clusters} />
          </AnalysisRoot>
        </Root>
      </div>
    );
  }
}

const mapStateToProps = ({ clusters }) => ({
  clusters: clusters.list
});

const mapDispatchToProps = {
  dispatchFetchClusters: fetchClusters
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClusterManagerContainer)
);
