import { createAction } from 'redux-actions';

export const showNotification = createAction('APP_NOTIFICATION_SHOW');
export const hideNotification = createAction('APP_NOTIFICATION_HIDE');

export const unhandledErrorNotification = error => {
  let notification = {
    message: error.message,
    type: 'error',
    unhandledError: true,
  };

  if (error instanceof Error) {
    notification.stack = error.stack;
  }
  return showNotification(notification);
};