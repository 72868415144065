import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';

const LedgerRoot = styled.div`
  background-color: #ffffff;
`;
const LedgerHeaderRoot = styled.div`
  display: flex;
`;
const ButtonsLeft = styled.div`
  display: flex;
  flex-basis: 100%;
`;
const ButtonsRight = styled.div`
  display: flex;
  flex: 0 0 30%;
  text-align: right;
`;

const LedgerHeader = props => {
  const {
    entries,
    showFees,
    showChange,
    showUSD,
    onChangeShowChange,
    onChangeShowFees,
    onChangeShowUSD
  } = props;
  return (
    <LedgerHeaderRoot>
      <ButtonsLeft>
        <span>Show Change</span>
        <input
          type="checkbox"
          onChange={onChangeShowChange}
          checked={showChange}
        />
        <span>Show Fees</span>
        <input type="checkbox" onChange={onChangeShowFees} checked={showFees} />
        <span>Show USD</span>
        <input type="checkbox" onChange={onChangeShowUSD} checked={showUSD} />
      </ButtonsLeft>
      <ButtonsRight>Total Entries : {entries.length}</ButtonsRight>
    </LedgerHeaderRoot>
  );
};

const Ledger = props => {
  const {
    entries,
    onEditEntry,
    onSaveEntry,
    editEntryId,
    showFees,
    showChange,
    showUSD,
    onChangeShowChange,
    onChangeShowFees,
    onChangeShowUSD,
    entry,
    entryRowHeader
  } = props;

  const Entry = entry;
  const EntryRowHeader = entryRowHeader;

  return (
    <LedgerRoot>
      <LedgerHeader
        entries={entries}
        showChange={showChange}
        showFees={showFees}
        showUSD={showUSD}
        onChangeShowChange={onChangeShowChange}
        onChangeShowFees={onChangeShowFees}
        onChangeShowUSD={onChangeShowUSD}
      />
      <EntryRowHeader showUSD={showUSD} onChangeShowUSD={onChangeShowUSD} />
      {_.map(
        _.filter(
          entries,
          entry =>
            !(!showChange && entry.entryClass === 'change') &&
            !(!showFees && entry.entryClass === 'fee')
        ),
        (entry, index) => (
          <Entry
            key={entry.id}
            index={index}
            entry={entry}
            onEditEntry={onEditEntry}
            onSaveEntry={onSaveEntry}
            editEntryId={editEntryId}
            showUSD={showUSD}
          />
        )
      )}
    </LedgerRoot>
  );
};

class EntityLedger extends Component {
  state = {
    entries: [],
    showChange: false,
    showFees: false,
    showUSD: false
  };

  generateLedger(entity) {
    const entries = this.props.onBuildLedger(entity);
    this.setState({ entries });
  }

  componentDidMount() {
    const { entity, showFees, showChange } = this.props;
    this.generateLedger(entity);
    this.setState({ showFees: !!showFees });
    this.setState({ showChange: !!showChange });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.entity && nextProps.entity !== this.props.entity) {
      this.generateLedger(nextProps.entity);
    }
  }

  render() {
    return (
      <Ledger
        {...this.props}
        entries={this.state.entries}
        showChange={this.state.showChange}
        showFees={this.state.showFees}
        showUSD={this.state.showUSD}
        onChangeShowChange={() =>
          this.setState(prevState => ({
            showChange: !prevState.showChange
          }))
        }
        onChangeShowFees={() =>
          this.setState(prevState => ({ showFees: !prevState.showFees }))
        }
        onChangeShowUSD={() =>
          this.setState(prevState => ({ showUSD: !prevState.showUSD }))
        }
      />
    );
  }
}

export default EntityLedger;
