import { handleActions } from 'redux-actions';
import { fetchTx } from 'containers/visualizer/tx-analysis/actions';

const txReducer = handleActions(
  {
    [fetchTx.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchTx.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoading: false,
      tx: action.payload
    }),
    [fetchTx.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    })
  },
  {
    isLoading: false,
    tx: null
  }
);

export default txReducer;
