import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  SatoshisAsBitcoin,
  SatoshisAsDollars,
  BlockDateTime
} from 'components/btc';
import { Credit, Debit, BitcoinValue } from 'components/util';
import { Tags } from 'components/tags';
import TxLink from './link';

const Root = styled.div``;
const TransferTime = styled.div`
  font-size: smaller;
`;
const ShortTx = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
/*
const TxHash = styled.div`
  font-size: smaller;
`;

      <TxHash>
        <a href={`https://insight.bitpay.com/tx/${tx.hash}`} target="_blank">
          {tx.hash}
        </a>
      </TxHash>
*/

export default ({ tx, onDeleteTag }) => {
  return (
    <Root>
      <TxLink hash={tx.hash}>
        <ShortTx>{tx.hash}</ShortTx>
      </TxLink>
      <Tags onDelete={onDeleteTag} tags={_.get(tx, 'meta.tags')} />
      <TransferTime>
        <BlockDateTime time={tx.time} />
      </TransferTime>
      <Credit>
        <BitcoinValue>
          <SatoshisAsBitcoin amount={tx.valueIn} />
        </BitcoinValue>
        <BitcoinValue>
          <SatoshisAsDollars amount={tx.valueIn} price={tx.avgDailyPrice} />
        </BitcoinValue>
      </Credit>
      <Debit>
        <BitcoinValue>
          <SatoshisAsBitcoin amount={tx.valueOut} />
        </BitcoinValue>
      </Debit>
      <BitcoinValue>
        <SatoshisAsBitcoin amount={tx.fees} />
      </BitcoinValue>
    </Root>
  );
};
