
import React from 'react';
import IconBase from './base';

const CrossHair = (props) => (
  <IconBase {...props} viewBox="0 0 17 16">
  <g transform="translate(1.000000, 0.000000)" fill="#434343">
    <path d="M7.979,10 C9.07197152,10 9.958,9.03782126 9.958,7.957 C9.958,6.87617874 9.07197152,6 7.979,6 C6.88602848,6 6,6.87617874 6,7.957 C6,9.03782126 6.88602848,10 7.979,10 Z" ></path>
    <rect x="7" y="0" width="1.98620605" height="4" ></rect>
    <path d="M7,12 L7,16.0025635 L9.00732422,16.0025635 L9.00732422,12 L7,12 Z" ></path>
    <rect x="0" y="7" width="3.98449707" height="1.96850586" ></rect>
    <path d="M12,7 L12.0000001,8.92785645 L15.9897461,8.92785645 L15.989746,7 L12,7 Z"></path>
  </g>
  </IconBase>
);

export default CrossHair;

