import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
  auth,
  component: PrivateComponent,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated ? (
        <PrivateComponent {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
