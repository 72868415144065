import _ from 'lodash';
import { fromSatoshis } from 'util/btc';
import toCyto from './to-cyto';
import { format as numberFormat } from 'd3-format';
import { isChangeOutput } from 'util/btc';

// Transaction Graph
// this builds a logical graph of transactions and addresses
// the graph is simply 2 dictionaries of addresses and transactions
//
// In a perfect world like a ledger we would just draw accounts (addresses) with
// each edge representing a movement of money between accounts
// this is easy as long as the value in and out are the same
//
// With Bitcoin transactions you can have multiple inputs (which eventually we can combine via clustering)
// once we cluster then we can drop down to just using edges as the transactions, however if we still want to be able to show individual
// addresses rather than accounts (clusters, wallets) we will need this model

// The theory is to split up the block chain in to 2 items, addresses and transactions
// we do not attach edges
// This a

const roundN = (num, n) => {
  return parseFloat(
    Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
  ).toFixed(n);
};
const edgeNameBtc = value => {
  let precision = 2;
  let sats = fromSatoshis(value);
  if (sats < 0.001) {
    precision = 5;
  } else if (sats < 0.01) {
    precision = 4;
  } else if (sats < 0.1) {
    precision = 3;
  }
  return roundN(sats, precision);
};

const edgeNamePercent = ({ tx, value, isOutput }) => {
  if (!isOutput || tx.outputs.length < 3) {
    return '';
  }
  let percent = 0;
  if (tx.valueOut > 0) {
    percent = (value / tx.valueOut) * 100;
  }
  if (percent < 0.09) {
    return '';
  }
  return ` : ${numberFormat(',.1f')(percent)}%`;
};

const edgeNameDollars = ({ tx, value, isOutput }) => {
  return `${edgeNameBtc(value)} : $${roundN(
    fromSatoshis(value) * tx.avgDailyPrice,
    2
  )}${edgeNamePercent({ tx, value, isOutput })}`;
};

const updateTimeRange = (dst, src) => {
  if (src) {
    if (dst) {
      if (dst.start === undefined || dst.end === undefined) {
        dst.start = src.start;
        dst.end = src.end;
      }
      if (src.start < dst.start) {
        dst.start = src.start;
      }
      if (src.end > dst.end) {
        dst.end = src.end;
      }
    }
  }
};

// neither of these change functions work on wallets...
const findInputIndex = (tx, address) => {
  return _.findIndex(tx.inputs, { address: address });
};

const findOutputIndex = (tx, address) => {
  return _.findIndex(tx.outputs, { address: address });
};

// add a tx node to the dictionary
const addTxToDictionary = (dictionaryTx, tx) => {
  if (!dictionaryTx[tx.hash]) {
    dictionaryTx[tx.hash] = {
      type: 'tx',
      id: tx.hash,
      timeRange: { start: tx.time, end: tx.time },
      referenceObject: tx
    };
  }

  return dictionaryTx[tx.hash];
};

// add an address node to the dictionary
const addAddressToDictionary = ({ graph, address, data }) => {
  let dictionary = graph.dictionaryAddress;
  let hash = address.address;
  data = data || {};

  let node = dictionary[hash];

  if (!node) {
    node = {
      id: hash,
      address: hash,
      timeRange: {},
      referenceCount: 1,
      referenceObject: address
    };

    _.assign(node, data);
    dictionary[hash] = node;
  } else {
    node.referenceCount++;
    node.referenceObject = address;
    updateTimeRange(node.timeRange, data.timeRange);

    if (data.primaryAddress) {
      node.primaryAddress = true;
    }
    if (data.addressOfInterest) {
      node.addressOfInterest = true;
    }
  }
  return node;
};

const txEdgeId = (tx, dir, idx) => {
  return dir + idx + '-' + tx.hash;
};

const createGraph = () => ({
  dictionaryAddress: {},
  dictionaryTx: {}
});

// this is the more complex function as it takes in a transaction for the block chain
// and creates both addr and tx nodes and assigns edges appropriately

const addTransactionToGraph = (graph, tx) => {
  const localAddressDictionary = {};
  ///////////////////////////////////////////////////////////
  // Add address to a local dictionary .. the local dictionary will have all addresses for the transaction
  //
  const addAddressLocal = hash => {
    // add to local dictionary
    let localNode = localAddressDictionary[hash];

    if (!localNode) {
      localNode = {
        addr: hash,
        edgeList: []
      };
      localAddressDictionary[hash] = localNode;
    }
    return localNode;
  };
  ///////////////////////////////////////////////////////////

  // first add the tx node
  const txNode = addTxToDictionary(graph.dictionaryTx, tx);

  // now add all input addresses and also build up a local dictionary of addresses only for this tx
  // and put edges on them so we can later pull them off to build a complete list of edges
  // for the transaction
  _.each(tx.inputs, (inp, idx) => {
    // add the address to the global dictionary
    addAddressToDictionary({
      graph,
      address: inp,
      data: {
        timeRange: { start: tx.time, end: tx.time }
      }
    });

    // create and add the edge for the input
    // source is the new address and target is the address on which this transaction was found
    const edge = {
      id: txEdgeId(tx, 'i', idx),
      timeRange: { start: tx.time, end: tx.time },
      source: inp.address,
      target: tx.hash,
      isInput: true,
      idx,
      sourceReferenceObject: inp,
      targetReferenceObject: tx
    };

    const changeIndex = findOutputIndex(tx, inp.address);

    // if we're also showing return paths we shouldn't do this debitwithchange  type edge
    if (changeIndex > -1) {
      edge.name = edgeNameDollars({
        tx,
        value: inp.value - tx.outputs[changeIndex].value
      });
      edge.type = 'debitwithchange';
    } else {
      edge.name = edgeNameDollars({ tx, value: inp.value });
      edge.type = 'debit';
    }
    // add the edge to the local dictionary
    const localAddress = addAddressLocal(inp.address);
    localAddress.edgeList.push(edge);
  });

  // now do all outputs on the transaction
  _.each(tx.outputs, (out, idx) => {
    let additional = { timeRange: { start: tx.time, end: tx.time } };

    addAddressToDictionary({ graph, address: out, data: additional });

    let edgeNode = {
      id: txEdgeId(tx, 'o', idx),
      name: edgeNameDollars({ tx, value: out.value, isOutput: true }),
      timeRange: { start: tx.time, end: tx.time },
      source: tx.hash,
      target: out.address,
      type: 'credit',
      isInput: false,
      idx,
      meta: out.meta,
      targetIsAddress: true,
      sourceReferenceObject: tx,
      targetReferenceObject: out
    };

    if (isChangeOutput(out)) {
      edgeNode.type = 'change';
    }
    if (findInputIndex(tx, out.address) > -1) {
      // don't draw self referencing change edges
      edgeNode = null;
      //edgeNode.type = 'change';
    }

    if (edgeNode) {
      let localAddress = addAddressLocal(out.address);
      localAddress.edgeList.push(edgeNode);
    }
  });

  // pull all the edges off the local address map and put on the txnode
  txNode.edgeList = [];
  _.forOwn(localAddressDictionary, localAddress => {
    _.each(localAddress.edgeList, edge => {
      txNode.edgeList.push(edge);
    });
  });

  return txNode;
};

const graphFromNetwork = network => {
  const graph = createGraph();

  _.each(network.txs, tx => {
    addTransactionToGraph(graph, tx);
  });

  if (network.primaryAddress) {
    const node = graph.dictionaryAddress[network.primaryAddress.address];
    if (node) {
      node.primaryAddress = true;
    }
  }
  _.each(network.interestingAddresses, address => {
    const node = graph.dictionaryAddress[address];
    if (node) {
      node.addressOfInterest = true;
    }
  });

  return toCyto(graph);
};

export { graphFromNetwork };
