import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { TaggedAddressLink } from 'components/address';

export const AddressList = styled.div`
  background: #ffffff;
  height: 600px;
  overflow-y: auto;
  font-size: smaller;
`;

const TaggedAddresses = props => {
  const { tags } = props;
  return (
    <AddressList>
      {_.map(
        tags
          ? tags.sort((a, b) => {
              return a.name.toLowerCase() === b.name.toLowerCase()
                ? 0
                : a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : -1;
            })
          : null,
        tag => (
          <div key={tag._id}>
            <TaggedAddressLink tag={tag} />
          </div>
        )
      )}
    </AddressList>
  );
};

export default TaggedAddresses;
