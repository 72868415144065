import React from 'react';
import {
  formatBlockDateTime,
  formatSatoshisAsBitcoin,
  fromSatoshis
} from 'util/btc';
import { format as numberFormat } from 'd3-format';

export const BlockDateTime = ({ time }) => (
  <React.Fragment>{formatBlockDateTime(time)}</React.Fragment>
);
export const SatoshisAsBitcoin = ({ amount }) => (
  <React.Fragment>{formatSatoshisAsBitcoin(amount)}</React.Fragment>
);

export const SatoshisAsDollars = ({ amount, price }) => (
  <React.Fragment>
    {numberFormat(',.2f')(fromSatoshis(amount) * price)}
  </React.Fragment>
);
