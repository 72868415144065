import { createAPIAction } from 'util/api';
import { createAction } from 'redux-actions';

export const logout = createAction('logout');

export const login = createAPIAction('login', {
  endpoint: '/auth/login',
  method: 'POST',
  body: formData => formData
});
