import _ from 'lodash';
import { fromBlockTime, fromSatoshis, isChangeOutput } from 'util/btc';
import { getTaggedAddressName, getTaggedTxName } from 'components/meta';

let globalId = 1;

const createReferenceAddress = address => {
  return {
    name: getTaggedAddressName(address),
    hash: address.address
  };
};

const createInboundReferenceAddress = (input, tx) => {
  if (tx.inputs[0].clusterHash) {
    return {
      name: `${tx.inputs[0].clusterHash}`,
      hash: tx.inputs[0].clusterHash,
      cluster: true
    };
  }
  const address = input;
  return {
    name: getTaggedAddressName(address),
    hash: address.address
  };
};

const createReferenceTx = tx => {
  return {
    name: getTaggedTxName(tx),
    hash: tx.hash
  };
};

const generateId = () => {
  globalId += 1;
  return globalId;
};

const newLedgerEntry = tx => ({
  id: generateId(),
  tx: createReferenceTx(tx),
  time: fromBlockTime(tx.block.time),
  avgDailyPrice: tx.avgDailyPrice,
  entryType: 'unknown'
});

export const buildLedger = tx => {
  let entries = [];

  if (!tx) {
    return entries;
  }

  if (tx.fees) {
    entries.push({
      ...newLedgerEntry(tx),
      amount: {
        btc: -tx.fees,
        dollars: -fromSatoshis(tx.fees) * tx.avgDailyPrice
      },
      balance: {},
      referenceAddress: { name: '', hash: '' },
      description: 'tx fee',
      entryType: 'debit',
      entryClass: 'fee'
    });
  }

  _.each(tx.outputs, output => {
    const isChangeDebit = isChangeOutput(output);
    entries.push({
      ...newLedgerEntry(tx),
      amount: {
        btc: -output.value,
        dollars: -fromSatoshis(output.value) * tx.avgDailyPrice
      },
      balance: {},
      referenceAddress: createReferenceAddress(output),
      entryType: 'debit',
      description: isChangeDebit ? 'change debit' : null,
      entryClass: isChangeDebit ? 'change' : null
    });
  });

  _.each(tx.inputs, input => {
    entries.push({
      ...newLedgerEntry(tx),
      amount: {
        btc: input.value,
        dollars: fromSatoshis(input.value) * tx.avgDailyPrice
      },
      balance: {},
      entryType: 'credit',
      referenceAddress: createInboundReferenceAddress(input, tx) // this should be the "wallet address"
    });
  });

  _.reverse(entries);

  let balance = 0;
  _.each(entries, entry => {
    if (entry.entryClass !== 'change') {
      balance += entry.amount.btc;
    }
    entry.balance.btc = balance;
    entry.balance.dollars = fromSatoshis(balance) * tx.avgDailyPrice;
  });
  return entries;
};
