// @flow
import _ from 'lodash';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import * as Styles from 'components/styles';
import { buttonType, getSizingProps } from './style-utils';

export type ButtonSize = 'normal' | 'small' | 'large';

const buttonSizeIconMap: { [ButtonSize]: string } = {
  normal: 'medium',
};

type Props = {
  alt: boolean,
  buttonType: 'normal' | 'primary' | 'warning',
  children?: React$Node,
  className?: ?string,
  disabled?: boolean,
  icon?: React$ComponentType<*>,
  iconProps?: Object,
  iconSize?: number,
  isLink: boolean,
  size: ButtonSize,
  type: 'button' | 'submit' | 'reset',
};

class Button extends Component<Props> {
  static defaultProps = {
    alt: false,
    buttonType: 'normal',
    disabled: false,
    isLink: false,
    size: 'normal',
    type: 'button',
  };

  render() {
    const {
      alt,
      buttonType,
      children,
      className,
      disabled,
      icon: Icon,
      iconProps,
      iconSize, // TODO: remove?
      isLink,
      size,
      ...props
    } = this.props;

    const hasValidChild = !_.isNil(children) && children !== false;

    return (
      <button className={className} disabled={disabled} {...props}>
        <ButtonBody>
          {!!Icon && (
            <Icon size={buttonSizeIconMap[size] || size} {...iconProps} />
          )}
          {hasValidChild && <ButtonContent>{children}</ButtonContent>}
        </ButtonBody>
      </button>
    );
  }
}

// STYLES

const ButtonContent = styled.div`
  flex: 1 0 auto;

  .icon + & {
    margin-left: 8px;
  }
`;

const ButtonBody = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  background: #fff;
  border: 1px solid ${Styles.BORDER_COLOR};
  border-radius: 4px;
  color: #444;
  cursor: pointer;
  ${p => getSizingProps(p.size)} outline: none;
  position: relative;
  transition: all 0.2s linear;
  user-select: none;
  white-space: nowrap;

  &:hover {
    border-color: ${Styles.FIELD_FOCUS_BORDER_COLOR};
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  &:focus {
    ${Styles.focus()};
  }

  ${p =>
    p.disabled &&
    css`
      border: 1px solid ${Styles.BORDER_COLOR};
      box-shadow: none;
      color: #e0e5ee;
      cursor: not-allowed;

      &:hover,
      &:focus {
        border: 1px solid ${Styles.BORDER_COLOR};
        box-shadow: none;
      }
    `} ${p =>
      p.isLink &&
      css`
        background: none;
        border: 0;
        border-radius: 0;
        color: blue;
        font-size: inherit;
        height: auto;
        padding: 0;
        vertical-align: middle;
        white-space: normal;

        &:active,
        &:disabled {
          background-color: transparent;
          border: 0;
        }

        ${p =>
          p.disabled &&
          css`
            background-color: transparent;
            border: 0;
          `} &:hover {
          box-shadow: none;
        }
      `} ${p =>
      p.alt &&
      css`
        background: transparent;
      `} ${p =>
      p.buttonType === 'primary' && buttonType('#00AB6B', '#FFF')} ${p =>
      p.buttonType === 'warning' && buttonType('#D14A3C', '#FFF')};
`;

export default StyledButton;