import _ from 'lodash';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { queryAddrMeta, queryTxMeta } from 'components/with-meta/actions';
import analysisChainReducer from 'containers/visualizer/address-analysis/reducer';
import analysisTxReducer from 'containers/visualizer/tx-analysis/reducer';

const metaReducer = handleActions(
  {
    [queryAddrMeta.REQUEST_TYPE]: state => ({
      ...state,
      isLoadingAddr: true
    }),

    [queryAddrMeta.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoadingAddr: false,
      addrList: (action => {
        const list = [];
        _.each(action.payload.list, meta => {
          _.each(meta.tags, tag => {
            list.push({ ...tag, hash: meta.hash, meta: meta });
          });
        });
        return list;
      })(action)
    }),
    [queryAddrMeta.FAILURE_TYPE]: state => ({
      ...state,
      isLoadingAddr: false
    }),
    [queryTxMeta.REQUEST_TYPE]: state => ({
      ...state,
      isLoadingTx: true
    }),

    [queryTxMeta.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoadingTx: false,
      txList: (action => {
        const list = [];
        _.each(action.payload.list, meta => {
          _.each(meta.tags, tag => {
            list.push({ ...tag, hash: meta.hash, meta: meta });
          });
        });
        return list;
      })(action)
    }),
    [queryTxMeta.FAILURE_TYPE]: state => ({
      ...state,
      isLoadingTx: false
    })
  },
  {
    isLoadingAddr: false,
    isLoadingTx: false,
    addrList: null,
    txList: null
  }
);

export default combineReducers({
  meta: metaReducer,
  analysisChain: analysisChainReducer,
  analysisTx: analysisTxReducer
});
