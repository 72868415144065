import React from 'react';
import EntityLedger from './entity-ledger';
import { buildLedger as buildAddressLedger } from './address/builder';
import { buildLedger as buildClusterLedger } from './cluster/builder';
import { buildLedger as buildTxLedger } from './tx/builder';
import AddressEntry, {
  EntryRowHeader as AddressEntryRowHeader
} from './address/entry-row';
import TxEntry, { EntryRowHeader as TxEntryRowHeader } from './tx/entry-row';

export const AddressLedger = props => {
  return (
    <EntityLedger
      {...props}
      entity={props.address}
      onBuildLedger={buildAddressLedger}
      entry={AddressEntry}
      entryRowHeader={AddressEntryRowHeader}
    />
  );
};

export const ClusterLedger = props => {
  return (
    <EntityLedger
      {...props}
      entity={props.cluster}
      onBuildLedger={buildClusterLedger}
    />
  );
};

export const TxLedger = props => {
  return (
    <EntityLedger
      {...props}
      entity={props.tx}
      onBuildLedger={buildTxLedger}
      entry={TxEntry}
      entryRowHeader={TxEntryRowHeader}
      showFees={true}
    />
  );
};
