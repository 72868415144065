import React from 'react'

export default () => (
  <div>
    <h1>About txLedger</h1>
    <p>
      txLedger helps you track transactions through the Bitcoin block chain.
    </p>
    API Access currently at : {process.env.REACT_APP_API_ROOT}
  </div>
)