import { createAPIAction } from 'util/api';

export const queryAddrMeta = createAPIAction('visualizer_query_address_meta', {
  endpoint: '/meta/addr/query',
  method: 'POST',
  body: () => ({ limit: 1000 })
});

export const addAddrMeta = createAPIAction('visualizer_add_addr_meta', {
  endpoint: '/meta/addr',
  method: 'POST',
  body: ({ meta }) => meta
});
export const updateAddrMeta = createAPIAction('visualizer_update_addr_meta', {
  endpoint: ({ meta }) => `/meta/addr/${meta._id}`,
  method: 'POST',
  body: ({ meta }) => meta
});

export const fetchAddrMeta = createAPIAction('visualizer_fetch_addr_meta', {
  endpoint: ({ id }) => `/meta/addr/${id}`,
  method: 'GET'
});
export const fetchAddrMetaByHash = createAPIAction(
  'visualizer_fetch_addr_meta_by_hash',
  {
    endpoint: ({ hash }) => `/meta/addr/hash/${hash}`,
    method: 'GET'
  }
);
export const deleteAddrMeta = createAPIAction('visualizer_delete_addr_meta', {
  endpoint: ({ id }) => `/meta/addr/${id}`,
  method: 'DELETE'
});

export const queryTxMeta = createAPIAction('visualizer_query_tx_meta', {
  endpoint: '/meta/tx/query',
  method: 'POST',
  body: () => ({ limit: 1000 })
});

export const addTxMeta = createAPIAction('visualizer_add_tx_meta', {
  endpoint: '/meta/tx',
  method: 'POST',
  body: ({ meta }) => meta
});
export const updateTxMeta = createAPIAction('visualizer_update_tx_meta', {
  endpoint: ({ meta }) => `/meta/tx/${meta._id}`,
  method: 'POST',
  body: ({ meta }) => meta
});

export const fetchTxMeta = createAPIAction('visualizer_fetch_tx_meta', {
  endpoint: ({ id }) => `/meta/tx/${id}`,
  method: 'GET'
});
export const fetchTxMetaByHash = createAPIAction(
  'visualizer_fetch_tx_meta_by_hash',
  {
    endpoint: ({ hash }) => `/meta/tx/hash/${hash}`,
    method: 'GET'
  }
);
export const deleteTxMeta = createAPIAction('visualizer_delete_tx_meta', {
  endpoint: ({ id }) => `/meta/tx/${id}`,
  method: 'DELETE'
});
