import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerIcon } from 'components/icons';
import Button from './default';

const submittingIconProps = {
  spin: true,
};

const SubmitButton = ({
  buttonType = 'primary',
  children,
  disabled,
  icon,
  submitting,
  submittingText,
  type = 'submit',
  ...props
}) => {
  const content =
    submitting && !_.isNil(submittingText) ? submittingText : children;

  return (
    <Button
      buttonType={buttonType}
      disabled={disabled || submitting}
      icon={submitting ? SpinnerIcon : icon}
      iconProps={submitting ? submittingIconProps : null}
      type={type}
      {...props}
    >
      {content}
    </Button>
  );
};

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default SubmitButton;