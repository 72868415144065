import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, SubmitButton } from 'components/button';

export default class ConfirmModal extends Component {
  static propTypes = {
    // Awkward, this interface should be `onClose`
    closePortal: PropTypes.func,
  };

  state = {
    submitting: false,
  };

  handleConfirm = async event => {
    this.setState({ submitting: true });
    await this.props.onConfirm(event);
    this.props.closePortal();
  };

  render() {
    const {
      confirmMessage,
      confirmButtonText,
      submittingText,
      show,
      confirmButtonType,
    } = this.props;

    return (
      <div>
        <StyledModal isVisible={show}>
          <ModalContent>
            <ModalBody>
              <h2>{confirmMessage}</h2>
            </ModalBody>

            <ModalFooter>
              <CancelButton onClick={this.props.closePortal} isLink>
                Cancel
              </CancelButton>
              <SubmitButton
                buttonType={confirmButtonType}
                onClick={this.handleConfirm}
                submitting={this.state.submitting}
                submittingText={submittingText}
              >
                {confirmButtonText}
              </SubmitButton>
            </ModalFooter>
          </ModalContent>
        </StyledModal>
        <ModalOverlay isVisible={show} />
      </div>
    );
  }
}

// STYLES

const ModalContent = styled.div`
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.02);
  position: relative;
  border-radius: 4px;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s;
`;

const ModalOverlay = styled.div`
  background: rgba(239, 239, 239, 0.9);
  height: 100%;
  left: 0;
  opacity: ${p => (p.isVisible ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 1000;
`;

const Modal = styled.div`
  backface-visibility: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2000;
  ${p =>
    p.isVisible &&
    css`
      visibility: visible;
      > ${ModalContent} {
        opacity: 1;
        transform: scale(1);
      }
    `};
`;

const StyledModal = Modal.extend`
  max-width: 450px;
  h2 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
  }
`;

const ModalBody = styled.div`
  padding: 32px 24px 24px;
`;

const ModalFooter = styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-end;
  padding: 8px 24px 16px;
  ${Button} {
    margin-right: 32px;
  }
`;

const CancelButton = styled(Button)`
  color: #aaa;
  margin-right: 32px;
  &:hover {
    color: #444;
  }
`;
