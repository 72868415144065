
import React from 'react';
import IconBase from './base';

const ArrowCircle = (props) => (
  <IconBase {...props} viewBox="0 0 17 16">
        <g transform="translate(1.000000, 0.000000)" fill="#434343">
            <path d="M15.945,9.738 L14.506,9.312 C14.513,9.179 14.523,9.048 14.523,8.916 C14.523,5.386 12.104,2.323 8.771,1.635 L8.51,2.996 C11.219,3.557 13.185,6.045 13.185,8.916 L13.185,8.922 L11.883,8.537 C11.617,8.691 11.521,9.042 11.67,9.318 L12.852,11.525 C13.002,11.801 13.338,11.9 13.606,11.748 L15.733,10.519 C15.999,10.364 16.094,10.016 15.945,9.738 L15.945,9.738 Z" ></path>
            <path d="M1.438,8.626 C1.438,6.273 2.764,4.172 4.738,3.186 L5.35,4.844 C5.651,4.982 6.004,4.843 6.138,4.53 L7.209,2.04 C7.341,1.727 7.207,1.361 6.905,1.222 L4.505,0.112 C4.203,-0.029 3.852,0.113 3.717,0.425 L4.258,1.89 C1.778,3.083 0.1,5.695 0.1,8.627 C0.1,9.451 0.235,10.273 0.504,11.07 L1.766,10.613 C1.548,9.962 1.438,9.294 1.438,8.626 L1.438,8.626 Z" ></path>
            <path d="M7.713,14.607 C6.667,14.607 5.648,14.344 4.758,13.796 L5.885,12.608 C5.882,12.265 5.614,12.01 5.282,12.013 L2.656,12.013 C2.324,12.015 2.06,12.294 2.063,12.638 L2.081,15.366 C2.084,15.709 2.352,15.984 2.685,15.98 L3.801,14.804 C4.958,15.591 6.314,15.994 7.713,15.994 C9.726,15.994 11.659,15.099 13.018,13.54 L12.026,12.609 C10.92,13.879 9.348,14.607 7.713,14.607 L7.713,14.607 Z" ></path>
        </g>
  </IconBase>
);

export default ArrowCircle;

