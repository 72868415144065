import styled from 'styled-components';
import * as Styles from 'components/styles';

export const SimpleButton = styled.div`
  align-items: center;
  display: flex;
  width: ${props => props.width}px;
  justify-content: center;
  background: #fff;
  border: 1px solid ${Styles.BORDER_COLOR};
  border-radius: 4px;
  color: #444;
  cursor: pointer;
  padding: 3px 4px;
  transition: all 0.2s linear;
  &:hover {
    border-color: ${Styles.FIELD_FOCUS_BORDER_COLOR};
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
`;
export default SimpleButton;