import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Alert from 'components/alert';
import { ApiError } from 'util/api';
import { hideNotification, showNotification } from './actions';
import { Button } from 'components/button';
import { ErrorBoundary, TopLevelError } from 'components/error';
import { Spinner as SpinnerAnimation } from 'components/svg';
import { PrivateRoute } from 'containers/auth/private-route';
import Login from 'containers/auth/login';
import { logout } from 'containers/auth/actions';
import Visualizer from 'containers/visualizer';
import Cluster from 'containers/cluster';
import About from 'containers/about';
import ClusterManager from 'containers/cluster-manager';
import BlockExplorer from 'containers/explorer/block-explorer';
import TxExplorer from 'containers/explorer/tx-explorer';

const Spinner = styled.div`
  width: 45px;
  height: 45px;
  margin-top: -15px;
  float: right;
`;
const AppRoot = styled.div`
  margin: 10px 10px;
`;
const Version = styled.span`
  font-size: small;
`;
const Alerts = styled.div`
  align-items: center;
  position: fixed;
  top: 30px;
  left: 50%;
  width: auto;
`;

const TitleBar = styled.div`
  margin-bottom: 10px;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #c0c0c0;
`;
const Title = styled.div`
  font-size: larger;
  color: #aaaaaa;
  margin-bottom: 5px;
  width: 20%;
  flex-basis: 100%;
`;
const StyledTitleLink = styled(Link)`
  font-size: larger;
  color: #aaaaaa;
  text-decoration: none;
`;
const TitleLeft = styled.div`
  display: flex;
`;
const MenuLink = styled(Link)`
  margin-right: 5px;
`;

const NavMenu = styled.div`
  margin-top: 5px;
  margin-right: 10px;
`;
const LoggedIn = styled.div`
  float: right;
`;

const NotFound = () => <div />;

const App = ({
  auth,
  isBusy,
  notifications,
  dispatchHideNotification,
  onLogout,
  version
}) => (
  <AppRoot>
    <header>
      <TitleBar>
        {auth.isAuthenticated && (
          <LoggedIn>
            {auth.user.email}{' '}
            <Button onClick={onLogout} size="small">
              Logout
            </Button>
          </LoggedIn>
        )}
        <TitleLeft>
          <Title>
            <StyledTitleLink to="/">
              txLedger<Version>- {version}</Version>
            </StyledTitleLink>

            {isBusy && (
              <Spinner>
                <SpinnerAnimation />
              </Spinner>
            )}
          </Title>
          <NavMenu>
            <MenuLink to="/cluster-manager">Clusters</MenuLink>
            <MenuLink to="/about-us">About</MenuLink>
          </NavMenu>
        </TitleLeft>
      </TitleBar>
    </header>

    <main>
      <Switch>
        <Route
          exact
          path="/login"
          render={props => <Login {...props} version={version} />}
        />
        <PrivateRoute auth={auth} exact path="/" component={Visualizer} />
        <PrivateRoute auth={auth} path="/address/:id" component={Visualizer} />
        <PrivateRoute auth={auth} path="/tx/:id" component={Visualizer} />
        <PrivateRoute
          auth={auth}
          path="/explore/block/:id"
          component={BlockExplorer}
        />
        <PrivateRoute
          auth={auth}
          path="/explore/tx/:id"
          component={TxExplorer}
        />
        <PrivateRoute auth={auth} path="/cluster/:id" component={Cluster} />
        <PrivateRoute auth={auth} exact path="/about-us" component={About} />
        <PrivateRoute
          auth={auth}
          exact
          path="/cluster-manager"
          component={ClusterManager}
        />
        <PrivateRoute auth={auth} component={NotFound} />
      </Switch>
    </main>
    <Alerts>
      {_.map(notifications, (item, index) => (
        <Alert
          key={index}
          {...item}
          onDismiss={dispatchHideNotification}
          dismissable={item.unhandledError}
          autoDismiss={!item.unhandledError}
        />
      ))}
    </Alerts>
  </AppRoot>
);

class AppContainer extends Component {
  static childContextTypes = {
    showNotification: PropTypes.func,
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  getChildContext() {
    return {
      showNotification: this.props.dispatchShowNotification,
      showSuccessNotification: message =>
        this.props.dispatchShowNotification({ message, type: 'success' }),
      showErrorNotification: message => {
        if (message instanceof ApiError) {
          message = message.message;
        }
        this.props.dispatchShowNotification({ message, type: 'error' });
      }
    };
  }
  handleError() {}

  handleLogout() {
    const { dispatchLogout } = this.props;

    dispatchLogout();
  }

  renderError = (error, errorInfo) => (
    <TopLevelError error={error} errorInfo={errorInfo} />
  );

  render() {
    return (
      <ErrorBoundary onError={this.handleError} renderError={this.renderError}>
        <App {...this.props} onLogout={this.handleLogout.bind(this)} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({ auth, app, visualizer }) => ({
  notifications: app.globalNotifications.notifications,
  isBusy: visualizer.analysisChain.isLoading,
  auth: auth
});

const mapDispatchToProps = {
  dispatchHideNotification: hideNotification,
  dispatchShowNotification: showNotification,
  dispatchLogout: logout
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppContainer)
);
