import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchCluster } from './actions';
import { AddressLink } from 'components/address';
import CollapsePanel from 'components/collapse-panel';
import { TxList } from 'components/tx';
import { ClusterLedger } from 'components/ledger';

const AddressList = styled.div`
  margin: 0px 0px 0px 10px;
`;
const Root = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  width: 95%;
`;
const AnalysisRoot = styled.div`
  flex: 0 0 80%;
  padding-left: 10px;
`;

const ClusterAnalysis = ({ cluster }) => {
  return (
    <div>
      {cluster.hash}
      <Root>
        <AddressList>
          {_.map(cluster.addresses, (addr, i) => (
            <div key={i}>
              <AddressLink hash={addr}> {addr}</AddressLink>
            </div>
          ))}
        </AddressList>
        {cluster.txs && (
          <AnalysisRoot>
            <CollapsePanel
              title="Transaction Detail"
              description={cluster.txs.length}
            >
              <TxList txs={cluster.txs} />
            </CollapsePanel>
            <CollapsePanel title="Ledger">
              <ClusterLedger cluster={cluster} />
            </CollapsePanel>
          </AnalysisRoot>
        )}
      </Root>
    </div>
  );
};

class ClusterContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  state = {};

  async fetchCluster(id) {
    const { dispatchFetchCluster } = this.props;

    const result = await dispatchFetchCluster({ id });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to load cluster. : ${result.payload.message}.`
      );
      return;
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchCluster(params.id);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;

    if (params.id !== prevProps.match.params.id) {
      this.fetchCluster(params.id);
    }
  }

  render() {
    const { cluster } = this.props;
    return (
      <div>
        {cluster.cluster && <ClusterAnalysis cluster={cluster.cluster} />}
      </div>
    );
  }
}

const mapStateToProps = ({ cluster }) => ({
  cluster
});

const mapDispatchToProps = {
  dispatchFetchCluster: fetchCluster
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClusterContainer)
);
