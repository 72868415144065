import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { ArrowDownIcon, ArrowUpIcon } from 'components/icons';
import { SimpleButton } from 'components/button';
import { formatBlockDateTime } from 'util/btc';

const Root = styled.div`
  display: flex;
`;
export const TxTimes = props => {
  const { txTimes, value, onChange, onClickStart, onClickEnd } = props;
  return (
    <Root>
      <select value={value} onChange={onChange}>
        {txTimes &&
          _.map(txTimes.list, time => (
            <option key={time} value={time}>
              {/*<BlockDateTime time={time} />*/}
              {formatBlockDateTime(time)}
            </option>
          ))}
      </select>

      <SimpleButton title="Go to Start" width="12" onClick={onClickStart}>
        <ArrowUpIcon size="medium" />
      </SimpleButton>
      <SimpleButton title="Go to End" width="12" onClick={onClickEnd}>
        <ArrowDownIcon size="medium" />
      </SimpleButton>
    </Root>
  );
};

export default TxTimes;
