import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { SatoshisAsBitcoin } from 'components/btc';
import CollapsePanel from 'components/collapse-panel';
import { Credit, Debit, BitcoinValue } from 'components/util';
import { TxDetails } from 'components/tx';
import { AddressLink } from 'components/address';

const Tx = styled.div`
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #a0a0b0;
`;

export const InputList = ({ inputs }) => {
  return (
    <div>
      {_.map(inputs, (input, index) => (
        <div key={index}>
          <Credit>
            <BitcoinValue>
              <SatoshisAsBitcoin amount={input.value} />
            </BitcoinValue>
          </Credit>
          <AddressLink hash={input.address}>{input.address} </AddressLink>
        </div>
      ))}
    </div>
  );
};
export const OutputList = ({ outputs }) => {
  return (
    <div>
      {_.map(outputs, (output, index) => (
        <div key={index}>
          <Debit>
            <BitcoinValue>
              <SatoshisAsBitcoin amount={output.value} />
            </BitcoinValue>
          </Debit>
          <AddressLink hash={output.address}>{output.address} </AddressLink>
        </div>
      ))}
    </div>
  );
};

export const TxList = ({ txs, onCluster, onSaveTag }) => {
  return (
    <div>
      {_.map(txs, tx => (
        <Tx key={tx.hash}>
          <TxDetails tx={tx} onCluster={onCluster} onSaveTag={onSaveTag} />
          <CollapsePanel
            background="green"
            title="Inputs"
            description={tx.inputs.length}
          >
            <InputList inputs={tx.inputs} />
          </CollapsePanel>
          <CollapsePanel
            background="red"
            title="Outputs"
            description={tx.outputs.length}
          >
            <OutputList outputs={tx.outputs} />
          </CollapsePanel>
        </Tx>
      ))}
    </div>
  );
};

export default TxList;
