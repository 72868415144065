import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

const sizeMap = {
    small: 12,
    medium: 14,
    large: 16,
  };
  
  const getStyleSize = (size) => {
    if (typeof size === 'string' && sizeMap[size]) {
      return css`
        height: ${sizeMap[size]}px;
        width: ${sizeMap[size]}px;
      `;
    }
  
    if (typeof size === 'number') {
      return css`
        height: ${size}px;
        width: ${size}px;
      `;
    }
  
    return '';
  };
let IconBase = ({
  children,
  onClick,
  className,
  viewBox = '0 0 512 512',
}) => (
  <svg
    children={children}
    className={classnames('icon', className)}
    onClick={onClick}
    viewBox={viewBox}
  />
);

IconBase.defaultProps = {
  fill: 'currentColor',
};

IconBase = styled(IconBase)`
  fill: ${p => p.fill || 'currentColor'};
  ${p => getStyleSize(p.size)};
`;

export default IconBase;