import _ from 'lodash';
import { fromBlockTime, fromSatoshis } from 'util/btc';
import { getTaggedAddressName, getTaggedTxName } from 'components/meta';

const isAddressInAddresses = (addresses, address) =>
  _.find(addresses, item => item === address);

//////////////////////////////////////////////////////////////////////////////////////////////////////////
function isCredit(tx) {
  if (tx.xferTypeClustered === 'credit') {
    return _.find(tx.outputs, output => output.typeClustered === 'credit');
  }
  if (tx.xferTypeClustered === 'debit+change') {
    return _.find(tx.outputs, output => output.typeClustered === 'change');
  }
  return false;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
function isDebit(tx) {
  if (
    tx.xferTypeClustered === 'debit' ||
    tx.xferTypeClustered === 'debit+change'
  ) {
    return true;
  }
  return false;
}
let globalId = 1;

const createReferenceAddress = address => {
  return {
    name: getTaggedAddressName(address),
    hash: address.address
  };
};

const createInboundReferenceAddress = tx => {
  if (tx.inputs[0].clusterHash) {
    return {
      name: `${tx.inputs[0].clusterHash}`,
      hash: tx.inputs[0].clusterHash,
      cluster: true
    };
  }
  /*
   this doesn't happen anymore.. coinbase is marked down below
  if (tx.inputs.length<1){
    return ({
      name:'Coinbase/UIA'
    });
  }
  */
  const address = tx.inputs[0];
  return {
    name: getTaggedAddressName(address),
    hash: address.address
  };
};

const createReferenceTx = tx => {
  return {
    name: getTaggedTxName(tx),
    hash: tx.hash
  };
};

const generateId = () => {
  globalId += 1;
  return globalId;
};

const newLedgerEntry = tx => ({
  id: generateId(),
  tx: createReferenceTx(tx),
  time: fromBlockTime(tx.block.time),
  blockTime: tx.block.time,
  avgDailyPrice: tx.avgDailyPrice,
  entryType: 'unknown'
});

const rank = entry => {
  const t = entry.blockTime * 10;
  if (entry.entryType === 'credit') {
    if (entry.entryClass === 'change') {
      return t + 2;
    }
    return t;
  }
  // debits
  if (entry.entryClass === 'change') {
    return t + 3;
  } else if (entry.entryClass === 'fee') {
    return t + 4;
  }
  return t + 1;
};

export const buildLedger = cluster => {
  let entries = [];

  if (!cluster) {
    return entries;
  }
  _.each(cluster.txs, tx => {
    let subEntries = [];
    const credit = isCredit(tx, cluster);

    if (credit) {
      const isChangeCredit = credit.typeClustered === 'change';

      subEntries.push({
        ...newLedgerEntry(tx),
        amount: credit.value,
        amountDollars: fromSatoshis(credit.value) * tx.avgDailyPrice,
        entryType: 'credit',
        referenceAddress: createInboundReferenceAddress(tx), // this should be the "wallet address"
        description: isChangeCredit ? 'change credit' : null,
        entryClass: isChangeCredit ? 'change' : null
      });
    }

    const debit = isDebit(tx, cluster);

    // if we're debiting this address and there are other inputs as well
    // which is common then we're part of a transaction involving a wallet
    // and we assume that all inputs to the transaction are part of the same account
    if (debit) {
      _.each(tx.outputs, output => {
        const isChangeDebit = isAddressInAddresses(
          cluster.addresses,
          output.address
        );

        subEntries.push({
          ...newLedgerEntry(tx),
          amount: -output.value,
          amountDollars: -fromSatoshis(output.value) * tx.avgDailyPrice,
          referenceAddress: createReferenceAddress(output),
          entryType: 'debit',
          description: isChangeDebit ? 'change debit' : null,
          entryClass: isChangeDebit ? 'change' : null
        });
      });
      // if it's a debit then we pay fees
      subEntries.push({
        ...newLedgerEntry(tx),
        amount: -tx.fees,
        amountDollars: -fromSatoshis(tx.fees) * tx.avgDailyPrice,
        referenceAddress: { name: '', hash: '' },
        description: 'tx fee',
        entryType: 'debit',
        entryClass: 'fee'
      });
    }

    subEntries = subEntries.sort((a, b) => rank(a) - rank(b));
    _.each(subEntries, subEntry => entries.push(subEntry));
  });

  entries = entries.sort((a, b) => rank(a) - rank(b));
  return entries;
};
