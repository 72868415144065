// @flow
import React from 'react';
import IconBase from './base';

const SpinnerIcon = (props) => (
  <IconBase {...props}>
    <path
      d="M512,259.3c-.66-40-8.16-70.66-22-102.1s-33.79-60.91-58.32-84.44S378,30.11,346.54,17.8A257,257,0,0,0,247.79,0,252.29,252.29,0,0,0,17,160.17a248.31,248.31,0,0,0,3.8,189.61A244.34,244.34,0,0,0,155.31,479a240.63,240.63,0,0,0,92.44,16.28A236.17,236.17,0,0,0,463.28,345.06,230.52,230.52,0,0,0,477,291.5c.63,0,1.29.07,2,.07,18.23,0,33-13,33-31.27,0-.92,0-1-.13-1Zm-52.48,84.15a227.74,227.74,0,0,1-125.63,120.2,223.74,223.74,0,0,1-170.68-3.83A220.47,220.47,0,0,1,93,410.46a217.86,217.86,0,0,1-45.55-71.81,215.42,215.42,0,0,1,3.83-164.34,212.75,212.75,0,0,1,47.59-67.46A210.12,210.12,0,0,1,168,63.06a207.09,207.09,0,0,1,158,3.83,204.15,204.15,0,0,1,64.79,45.76c18.26,19.22,32.63,43.72,42,68.19s13.82,38.45,13.12,78.45H446c-.07,0-.13,0-.13,1,0,17,12.88,30.16,29.46,31.94a223.43,223.43,0,0,1-15.89,51.67Z"
      transform="translate(-0.03 0.01)"
    />
  </IconBase>
);

export default SpinnerIcon;
