import _ from 'lodash';

const graphCss = {
  colors: {
    debitwithchange: '#804040',
    debit: '#ff0000',
    credit: '#00ff00',
    node: '#0000ff',
    edge: '#e0e0e0',
    address: '#0000ff',
    knownAddress: '#808000',
    change: '#888888',
    changeEdge: '#888888',
    changeBorder: '#111111',
    primaryBorder: '#ff00ff',
    interestingBorder: '#00ffff',
    tx: '#009060',
    knownTx: '#808000'
  }
};

const pad = n => {
  return n < 10 ? '0' + n : n;
};

const blocktimeToDate = input => {
  if (isNaN(input)) {
    return '';
  }
  let date = new Date(input * 1000);
  return (
    pad(date.getMonth() + 1) +
    '/' +
    pad(date.getDate()) +
    '/' +
    (date.getFullYear() + '').substring(2)
  );
};
const blocktimeToTime = input => {
  if (isNaN(input)) {
    return '';
  }
  let date = new Date(input * 1000);
  return pad(date.getHours()) + ':' + pad(date.getMinutes());
};

const addrName = addr => {
  let hash = addr;

  if (_.isObject(hash)) {
    hash = hash.address;
  }
  return hash.substr(0, 5) + '...';
};

const txName = tx => {
  return (
    blocktimeToDate(tx.timeRange.start) +
    ' ' +
    blocktimeToTime(tx.timeRange.start) +
    ' ' +
    tx.id.substr(0, 5) +
    '...'
  );
};

const assignEdgeColor = edge => {
  if (!edge.data.color) {
    switch (edge.data.type) {
      case 'credit':
        edge.data.color = graphCss.colors.credit;
        break;
      case 'debitwithchange':
        edge.data.color = graphCss.colors.debitwithchange;
        break;
      case 'debit':
        edge.data.color = graphCss.colors.debit;
        break;
      case 'change':
        edge.data.color = graphCss.colors.change;
        break;
      default:
        edge.data.color = graphCss.colors.edge;
        break;
    }
  }
};

const assignNodeColor = node => {
  if (!node.data.color) {
    switch (node.data.type) {
      case 'tx':
        node.data.color = graphCss.colors.tx;
        if (_.get(node, 'data.referenceObject.meta.tags', []).length > 0) {
          node.data.color = graphCss.colors.knownTx;
        }
        break;
      case 'addr':
        node.data.color = graphCss.colors.address;

        if (_.get(node, 'data.referenceObject.meta.tags', []).length > 0) {
          node.data.color = graphCss.colors.knownAddress;
        }
        break;
      default:
        node.data.color = graphCss.colors.node;
        break;
    }
  }

  node.data.borderColor = node.data.color;

  if (node.data.primaryAddress) {
    node.data.borderColor = graphCss.colors.primaryBorder;
  }
  if (node.data.addressOfInterest) {
    node.data.borderColor = graphCss.colors.interestingBorder;
  }
};

// take a graph.. 2 hash tables of addresses and transactions and create a cyto graph from them

const toCyto = graph => {
  let e;
  let elements = [];

  _.forOwn(graph.dictionaryAddress, addr => {
    e = { group: 'nodes', data: addr };
    e.data.type = 'addr';
    e.data.shape = 'ellipse';
    e.data.name = addrName(addr.id);
    e.data.timeRangeStart = addr.timeRange.start;
    e.data.timeRangeEnd = addr.timeRange.end;
    assignNodeColor(e);
    elements.push(e);
  });

  _.forOwn(graph.dictionaryTx, tx => {
    e = { group: 'nodes', data: tx };
    e.data.type = 'tx';
    e.data.shape = 'rectangle';
    e.data.name = txName(tx);
    e.data.timeRangeStart = tx.timeRange.start;
    e.data.timeRangeEnd = tx.timeRange.end;
    assignNodeColor(e);
    elements.push(e);
  });

  _.forOwn(graph.dictionaryTx, tx => {
    _.each(tx.edgeList, function(edge) {
      e = { group: 'edges', data: edge };
      assignEdgeColor(e);
      elements.push(e);
    });
  });

  return elements;
};

export default toCyto;
