// @flow
import React from 'react';
import IconBase from './base';

const SuccessIcon = (props) => (
  <IconBase {...props}>
    <path d="M359.51,159.14,205.91,313.85l-58.09-58.09-21.91,21.91,68.92,68.92c3.08,3.08,7.26,5.54,11,5.54s7.75-2.46,10.83-5.42L381.17,181.29Z" />
    <path d="M256,0C114.58,0,0,114.58,0,256S114.58,512,256,512,512,397.42,512,256,397.42,0,256,0Zm0,490.71C126.65,490.71,21.29,385.48,21.29,256S126.52,21.29,256,21.29,490.71,126.52,490.71,256,385.35,490.71,256,490.71Z" />
  </IconBase>
);

export default SuccessIcon;
