import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchTx } from './actions';
import CollapsePanel from 'components/collapse-panel';
import { clusterTx, clusterAddresses } from '../actions';
import { TxDetails, InputList, OutputList } from 'components/tx';
import FocusItem from 'components/focus-item';
import { NetworkGraph } from 'components/cytoscape';
import { TxLedger } from 'components/ledger';
import withMeta from 'components/with-meta';

const AnalysisRoot = styled.div`
  flex: 0 0 80%;
`;
const Root = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  width: 95%;
`;
const GraphAnalysis = ({
  analysisTx,
  onClusterAddresses,
  onChangeFocusItem,
  focusItem,
  onClusterTx,
  onSaveTag,
  showHidden,
  onSaveGraph,
  onRestoreGraph,
  onChangeChange
}) => {
  return (
    <React.Fragment>
      <NetworkGraph
        network={{ txs: [analysisTx] }}
        showHidden={showHidden}
        onSaveGraph={onSaveGraph}
        onRestoreGraph={onRestoreGraph}
        onCluster={onClusterAddresses}
        onItemClick={({ value }) => {
          onChangeFocusItem(value);
        }}
      />
      <FocusItem
        item={focusItem}
        onCluster={onClusterTx}
        onSaveTag={onSaveTag}
        onChangeChange={onChangeChange}
      />
    </React.Fragment>
  );
};
const TxAnalysis = ({
  analysisTx,
  onDeleteTag,
  onSaveTag,
  onClusterTx,
  onClusterAddresses,
  onChangeFocusItem,
  onChangeChange,
  focusItem
}) => {
  const inputOutputCount =
    _.get(analysisTx, 'inputs.length') + _.get(analysisTx, 'outputs.length');
  return (
    <AnalysisRoot>
      <TxDetails
        tx={analysisTx ? analysisTx : null}
        onDeleteTag={onDeleteTag}
        onCluster={onClusterTx}
        onSaveTag={onSaveTag}
      />
      {inputOutputCount > 5 ? (
        <CollapsePanel title="Input/Output List" description={inputOutputCount}>
          <InputList inputs={analysisTx.inputs} />
          <OutputList outputs={analysisTx.outputs} />
        </CollapsePanel>
      ) : (
        <React.Fragment>
          <InputList inputs={analysisTx.inputs} />
          <OutputList outputs={analysisTx.outputs} />
        </React.Fragment>
      )}
      {analysisTx ? (
        <React.Fragment>
          <GraphAnalysis
            analysisTx={analysisTx}
            onClusterAddresses={onClusterAddresses}
            onChangeFocusItem={onChangeFocusItem}
            focusItem={focusItem}
            onClusterTx={onClusterTx}
            onSaveTag={onSaveTag}
            onChangeChange={onChangeChange}
          />
          <TxLedger tx={analysisTx} />
        </React.Fragment>
      ) : (
        <div> Analysis is Disabled </div>
      )}
    </AnalysisRoot>
  );
};

class TxAnalysisContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };
  state = {
    focusItem: null
  };

  fetchTx = async obj => {
    const { dispatchFetchTx } = this.props;
    const result = await dispatchFetchTx({ ...obj });

    if (result.error) {
      this.context.showErrorNotification(
        `Failed to retrieve tx. : ${result.payload.message}.`
      );
      return;
    }
  };

  // this should happen outside of here in containing component
  async componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;
    const {
      match: { params: prevParams }
    } = prevProps;

    if (!prevParams || prevParams.id !== params.id) {
      this.fetchTx({ id: params.id });
    }
  }

  // doing this cause otherwise we don't load if we hit refresh
  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchTx({ id: params.id });
  }

  /*
  handleDeleteTag = async id => {
    const { dispatchDeleteTag } = this.props;
    const result = await dispatchDeleteTag({ id });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to delete tag. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification('tag deleted!');
  };

  handleAddTag = async data => {
    const { address, tx, tagText } = data;
    const { dispatchAddTag } = this.props;

    if (!tagText || tagText.length < 1) {
      return;
    }
    const newTag = {
      name: tagText
    };

    if (!address) {
      if (tx) {
        newTag.attachedTo = 'tx';
        newTag.hash = tx.hash;
      } else {
        return;
      }
    } else {
      newTag.attachedTo = 'addr';
      newTag.hash = address.address;
    }

    const result = await dispatchAddTag(newTag);

    if (result.error) {
      this.context.showErrorNotification(
        `Failed to add tag. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification(
      `tag ${tagText} added to ${newTag.hash}!`
    );
  };
  */

  handleClusterTx = async tx => {
    const { dispatchClusterTx } = this.props;
    const result = await dispatchClusterTx({ id: tx.hash });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to cluster tx. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification(`tx ${tx.hash} clustered `);
  };

  handleClusterAddresses = async selected => {
    const { dispatchClusterAddresses } = this.props;
    const result = await dispatchClusterAddresses({
      addresses: selected.addresses
    });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to cluster addresses. : ${result.payload.message}.`
      );
      return;
    }
    this.context.showSuccessNotification('clustered');
  };

  render() {
    const { analysisTx, onChangeChange, onSaveTag, onDeleteTxTag } = this.props;

    return (
      <div>
        <Root>
          {analysisTx && (
            <TxAnalysis
              analysisTx={analysisTx}
              onSaveTag={onSaveTag}
              onDeleteTag={id => onDeleteTxTag(analysisTx, id)}
              onClusterTx={this.handleClusterTx}
              onClusterAddresses={this.handleClusterAddresses}
              onChangeChange={onChangeChange}
              onChangeFocusItem={value => {
                if (this.state.focusItem) {
                  if (this.state.focusItem.id === value.id) {
                    if (this.state.focusItem.type === 'addr') {
                      return this.props.history.push(`/address/${value.id}`);
                    }
                    if (this.state.focusItem.type === 'tx') {
                      return this.props.history.push(`/tx/${value.id}`);
                    }
                  }
                }
                this.setState(() => ({ focusItem: value }));
              }}
              focusItem={this.state.focusItem}
            />
          )}
        </Root>
      </div>
    );
  }
}

const mapStateToProps = ({ visualizer }) => ({
  analysisTx: visualizer.analysisTx.item
});

const mapDispatchToProps = {
  dispatchFetchTx: fetchTx,
  dispatchClusterTx: clusterTx,
  dispatchClusterAddresses: clusterAddresses
};

const compose = _.flow(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withMeta
);

export default compose(TxAnalysisContainer);
