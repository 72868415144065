import React, { Component } from 'react';
import { withFormik, Field } from 'formik';
import styled from 'styled-components';
import { ModalButtons, CancelButton } from 'components/modal';
import { SubmitButton } from 'components/button';

const ModalForm = styled.form``;
const ModalFormContent = styled.div``;

class AddForm extends Component {
  componentDidMount() {
    this.textInput.focus();
  }
  render() {
    const { closePortal, handleSubmit, isSubmitting, description } = this.props;
    return (
      <ModalForm onSubmit={handleSubmit}>
        <ModalFormContent>
          <div>Tag Name</div>
          <Field
            innerRef={input => {
              this.textInput = input;
            }}
            type="text"
            name="tagText"
          />
          {description}
        </ModalFormContent>

        <ModalButtons>
          <CancelButton onClick={closePortal} isLink>
            Cancel
          </CancelButton>
          <SubmitButton
            buttonType="warning"
            onClick={e => {
              handleSubmit(e);
              closePortal();
            }}
            submitting={isSubmitting}
            submittingText="Saving"
          >
            OK
          </SubmitButton>
        </ModalButtons>
      </ModalForm>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    tagText: ''
  }),
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values);
  }
})(AddForm);
