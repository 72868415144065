import _ from 'lodash';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { showNotification,hideNotification } from './actions';

const globalNotifications = handleActions(
  {
    [showNotification]: (state, action) => ({
      ...state,
      notifications: [
        ...state.notifications,
        { ...action.payload, id: state.nextId },
      ],
      nextId: state.nextId + 1,
    }),

    [hideNotification]: (state, action) => ({
      ...state,
      notifications: _.filter(
        state.notifications,
        notification => notification.id !== action.payload.id,
      ),
    }),
  },
  {
    nextId: 0,
    notifications: [],
  },
);


export default combineReducers({
  globalNotifications,
});
