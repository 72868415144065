import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import cytoscape from 'cytoscape';
import cydagre from 'cytoscape-dagre';

cydagre(cytoscape);

export const getSelectedElements = elements => {
  const selection = _.reduce(
    elements,
    (sum, ele) => {
      const type = ele.data('type');
      const referenceObject = ele.data('referenceObject');

      if (type === 'addr') {
        sum.addresses.push(referenceObject);
      }
      if (type === 'tx') {
        sum.txs.push(referenceObject);
      }

      return sum;
    },
    { addresses: [], txs: [] }
  );

  return selection;
};

const Root = styled.div``;

let cyStyle = {
  height: '550px',
  display: 'block'
};

const conf = {
  //hideEdgesOnViewport: true,
  //hideLabelsOnViewport: true,
  //textureOnViewport: true,
  boxSelectionEnabled: true,
  layout: {
    //name:'preset',
    //name:'dagre',
    name: 'breadthfirst',
    directed: true,
    fit: true,
    padding: 5
  },
  style: cytoscape
    .stylesheet()
    .selector('node')
    .css({
      content: 'data(name)',
      shape: 'data(shape)',
      'background-color': 'data(color)',
      'text-outline-color': 'data(color)',
      'border-color': 'data(borderColor)',
      width: '120',
      height: '90',
      'border-width': 3,
      'text-valign': 'center',
      'text-wrap': 'wrap',
      color: 'white',
      'text-outline-width': 2
    })
    .selector('edge')
    .css({
      'curve-style': 'bezier',
      content: 'data(name)',
      'line-color': 'data(color)',
      'target-arrow-color': 'data(color)',
      width: '4',
      'target-arrow-shape': 'triangle',
      color: 'black',
      'text-outline-width': 1,
      'text-outline-color': 'white'
      //'source-arrow-shape': 'triangle'
    })
    .selector(':selected')
    .css({
      'background-color': 'black',
      'line-color': 'black',
      'target-arrow-color': 'black',
      'source-arrow-color': 'black'
    })
    .selector('.faded')
    .css({
      opacity: 0.65,
      'text-opacity': 0.65
    })
};

class CytoGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  destroycy() {
    if (this.state.cy) {
      console.log('cytoscape destroy');
      this.state.cy.destroy();
      this.setState({});
    }
  }

  componentWillReceiveProps(nextProps) {
    // for some reason this is getting called on a straight up click.. of an item

    if (nextProps.data !== this.props.data) {
      this.destroycy();
      console.log('cytoscape receive props createcy');
      //this.createcy(nextProps);
      conf.container = this.cyRef;
      conf.elements = nextProps.data;
      conf.ready = e => {
        console.log('cytoscape ready');
        //this.ready(e.cy);
        e.cy.on('tap', 'node,edge', e => {
          const { onItemClick } = this.props;
          if (onItemClick) {
            var data = e.target.data();
            onItemClick({ value: data });
          }
        });
        this.setState({ cy: e.cy });
      };
      console.log('cytoscape(conf) ');
      cytoscape(conf); // this can be expensive depending on size of graph
      //this.setState({ cy });
      console.log('cytoscape exit receive props');
    }
  }

  componentWillUnmount() {
    this.destroycy();
  }

  shouldComponentUpdate() {
    return false;
  }

  getSelected = () => {
    const cy = this.state.cy;
    return cy.elements(':selected');
  };

  fit = () => {
    const cy = this.state.cy;
    var eles = cy.elements(':selected');

    if (eles.length < 1) {
      eles = cy.elements(':visible');
    }
    cy.fit(eles, 5);
  };

  center = () => {
    const cy = this.state.cy;
    var eles = cy.elements(':selected');

    if (eles.length > 0) {
      cy.center(eles);
    } else {
      cy.center(cy.elements(':visible'));
    }
  };

  hideSelected = () => {
    const cy = this.state.cy;
    var eles = cy.elements(':selected');

    if (eles.length > 0) {
      eles.unselect();
      eles.hide();
      _.each(eles, ele => ele.data('hidden', true));
    }
  };

  removeSelected = () => {
    const cy = this.state.cy;
    var eles = cy.elements(':selected');

    if (eles.length > 0) {
      cy.remove(eles);
    }
  };

  findPrimary = () => {
    const cy = this.state.cy;
    const eles = cy.elements('node[primaryAddress]');

    if (eles.length > 0) {
      cy.fit(eles, 5);
    }
  };

  toJson = json => {
    const cy = this.state.cy;
    if (json) {
      return cy.json(json);
    } else {
      return cy.json();
    }
  };

  relayout = () => {
    const cy = this.state.cy;
    var eles = cy.elements(':selected');

    if (eles.length < 1) {
      eles = cy.elements(':visible');
    }
    cy.pan({ x: 0, y: 0 });
    eles.layout({ name: 'breadthfirst', fit: true, padding: 5 });
  };

  filterGraph = ({ lastTxTime, firstTxTime, showHidden }) => {
    const cy = this.state.cy;
    var eles = [];

    if (cy && cy.elements) {
      eles = cy.elements();
    }

    if (eles.length) {
      eles.show();
      if (lastTxTime) {
        var sel = '[timeRangeStart >' + lastTxTime + ']';
        eles = cy.elements(sel);
        eles.unselect();
        eles.hide();
        sel = '[timeRangeEnd <' + firstTxTime + ']';
        eles = cy.elements(sel);
        eles.unselect();
        eles.hide();
      }
      if (!showHidden) {
        //        sel =
        //          'node[!primaryAddress][!secondaryAddress][!potentialChangeAddress][type="addr"]';
        sel = 'node[hidden]';
        eles = cy.elements(sel);
        eles.unselect();
        eles.hide();
      }
    }
  };

  render() {
    return (
      <Root>
        <div style={cyStyle} ref={cyRef => (this.cyRef = cyRef)} />
      </Root>
    );
  }
}

export default CytoGraph;
/*[
  { data: { id: 'a' ,shape:'ellipse',name:'task-a',color:'#ff0000',borderColor:'#000000'} },
  { data: { id: 'b' ,shape:'ellipse',name:'task-b',color:'#00ff00',borderColor:'#000000'} },
  {
    data: {
      id: 'ab',
      source: 'a',
      target: 'b',
      task:'task-edge',
      status:'PENDING',
      name:'some edge',
      color:'#0000ff',
      borderColor:'#0000ff',
    }
  }
];*/
