import styled from 'styled-components';

export const BitcoinValue = styled.span`
  font-size: smaller;
  padding: 5px;
`;
export const Credit = styled.span`
  color: green;
`;
export const Debit = styled.span`
  color: red;
`;
export const CreditDebit = styled.span`
  color: ${props => (props.debit ? 'red' : 'green')};
`;
export const WarnText = styled.div`
  color: red;
`;
