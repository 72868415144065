import { createAPIAction } from 'util/api';

/*export const fetchTx = createAPIAction('fetch_tx', {
  endpoint: '/tx',
  method: 'POST',
  body: options => {
    return JSON.stringify(options);
  }
});*/
export const fetchTx = createAPIAction('fetch_tx', {
  endpoint: ({ id }) => `/tx/${id}`
});
