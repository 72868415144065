import _ from 'lodash';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');

export const generateApiTypes = name => {
  const cappedName = _.toUpper(name);

  return {
    requestType: `${cappedName}_REQUEST`,
    progressType: `${cappedName}_PROGRESS`,
    successType: `${cappedName}_SUCCESS`,
    failureType: `${cappedName}_FAILURE`
  };
};

// Small utility for creating FSAs
export const createActionWith = action => data => {
  const finalAction = _.merge({}, action, data);
  delete finalAction[CALL_API];
  return finalAction;
};

export const isValidAction = action =>
  _.isPlainObject(action) && action.hasOwnProperty(CALL_API);

export function ApiError(status, message) {
  this.name = 'ApiError';
  this.message = message;
  this.status = status;
  this.stack = new Error().stack;
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;

export const handleError = ({
  dispatch,
  error,
  failureType,
  requestData,
  onError,
  defaultOnError,
  logoutAction
}) => {
  if (error.status === 401) {
    if (typeof logoutAction !== 'function') {
      throw new Error('Invalid logoutAction passed');
    }

    const reset = logoutAction();
    reset.error = true;
    reset.payload = { message: 'You are not logged in.' };
    return dispatch(reset);
  }

  // We dispatch the a _FAILURE action so that we can respond to it
  // independently of any global onError.
  const result = dispatch({
    error: true,
    meta: {
      requestData
    },
    type: failureType,
    payload: error
  });

  let errorResult;

  // If an action has an `onError` callback, we call it first, then,
  // `defaultOnError` which is passed when constructing the middleware.
  // Note, if `onError` returns an Error or false, `defaultOnError` will
  // _not_ be called.
  if (typeof onError === 'function') {
    errorResult = onError(error, requestData);
  }

  if (errorResult instanceof Error) {
    throw errorResult;
  } else if (errorResult !== false && typeof defaultOnError === 'function') {
    dispatch(defaultOnError(error, requestData));
  }

  return result;
};

/**
 * Builds a prefixed api url string.
 *
 * With `REACT_APP_API_ROOT` env variable set to 'http://txledger.com:4002':
 * createApiUrl('/meta/addr') // http://txledger.com:4002/meta/addr
 *
 * Without:
 * createApiUrl('/meta/addr') // /api/meta/addr
 */
export const createApiUrl = path => {
  if (!path) {
    throw new Error('Path must be passed.');
  }

  const prefix = process.env.REACT_APP_API_ROOT || '/api/v1';
  return `${prefix.replace(/\/$/, '')}/${path.replace(/^\/|\/$/g, '')}`;
};

export const createAPIAction = (name, apiOptions) => {
  // Generate request type constants
  const {
    requestType,
    successType,
    failureType,
    progressType
  } = generateApiTypes(name);

  // Build our async API calling action creator
  //
  // Note, the action creator that gets returned assumes it will be
  // called with either a single value:
  //
  // `myAction(1)` or `myOtherAction(id)`
  //
  // or an object:
  //
  // `myAction({ id, data, onError })`
  const action = data => async (dispatch, getState) => {
    const state = getState();

    let onError;
    let referenceData;
    let parsedData = data;

    if (_.isPlainObject(data)) {
      ({ onError, referenceData, ...parsedData } = data);
    }

    const endpoint =
      typeof apiOptions.endpoint === 'function'
        ? apiOptions.endpoint(parsedData, state)
        : apiOptions.endpoint;

    const method =
      typeof apiOptions.method === 'function'
        ? apiOptions.method(parsedData, state)
        : _.defaultTo(apiOptions.method, 'GET');

    const options = {
      endpoint: createApiUrl(endpoint),
      contentType: apiOptions.contentType,
      referenceData,
      method,
      onError,
      requestData: parsedData,
      types: [requestType, successType, failureType, progressType]
    };

    if (apiOptions.body) {
      let body = apiOptions.body(parsedData, state);

      if (_.isPlainObject(body)) {
        body = JSON.stringify(body);
      }

      options.body = body;
    }

    const actionResult = {
      [CALL_API]: options
    };

    if (typeof apiOptions.meta === 'function') {
      actionResult.meta = apiOptions.meta(parsedData, state);
    }

    return dispatch(actionResult);
  };

  // Set request types for easy referencing in reducer
  action.REQUEST_TYPE = requestType;
  action.SUCCESS_TYPE = successType;
  action.FAILURE_TYPE = failureType;
  action.PROGRESS_TYPE = progressType;

  return action;
};

// CONTENT TYPE HANDLERS

export const EMPTY_CONTENT_CODES = [0, 204, 205];

const contentTypeResponseMap = {
  'text/html': res => res.text(),

  __default: async res => {
    if (EMPTY_CONTENT_CODES.indexOf(res.status) === -1) {
      let json;

      try {
        json = await res.json();
      } catch (e) {
        // TODO: we probably still want to actually handle cases where
        // there's an actual parse error?
        json = {};
      }

      return json;
    }
  }
};

export const getContentTypeResponse = async (contentType, result) =>
  (contentTypeResponseMap[contentType] || contentTypeResponseMap.__default)(
    result
  );

const contentTypeErrorMap = {
  'text/html': () => 'unknown error',
  __default: json => _.get(json, 'meta.errorDetail', 'unknown error')
};

export const getContentTypeError = (contentType, response) =>
  (contentTypeErrorMap[contentType] || contentTypeErrorMap.__default)(response);
