import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BlockDateTime } from 'components/btc';
import { fetchBlock } from './actions';
const Root = styled.div``;
const Entry = styled.div`
  display: flex;
  margin: 0px 10px 5px 10px;
  width: 95%;
`;
const Label = styled.div`
  flex: 0 0 20%;
`;
const Data = styled.div`
  flex: 0 0 80%;
`;
const Orphan = styled.span`
  color: red;
`;
const Hash = styled.span`
  font-size: smaller;
`;
const SimpleLink = styled(Link)`
  text-decoration: none;
`;

const BlockLink = ({ hash, isOrphan }) => {
  return (
    <Hash>
      <SimpleLink to={`/explore/block/${hash}`}>{hash}</SimpleLink>
      <Orphan>{isOrphan && ' (orphan)'}</Orphan>
    </Hash>
  );
};

const BlockAnalysis = ({ block }) => {
  return (
    <Root>
      <Entry>
        <Label>Height</Label>
        <Data>
          {block.height}
          <Orphan>{block.isOrphan && ' (orphan)'}</Orphan>
        </Data>
      </Entry>
      <Entry>
        <Label>Hash</Label>
        <Data>
          <Hash>{block.hash}</Hash>
        </Data>
      </Entry>
      <Entry>
        <Label>Previous Block Hash</Label>
        <Data>
          <BlockLink hash={block.previousHash} />
        </Data>
      </Entry>
      <Entry>
        <Label>Next Block Hash(es)</Label>
        <Data>
          {_.map(block.nextBlocks, next => (
            <div key={next.hash}>
              <BlockLink hash={next.hash} isOrphan={next.isOrphan} />
            </div>
          ))}
        </Data>
      </Entry>
      <Entry>
        <Label>Time</Label>
        <Data>
          <BlockDateTime time={block.time} />
        </Data>
      </Entry>
      <Entry>
        <Label>Bits</Label>
        <Data>{block.bits}</Data>
      </Entry>
      <Entry>
        <Label>Transactions</Label>
        <Data>{block.nTx}</Data>
      </Entry>
      <Entry>
        <Label>Size</Label>
        <Data>{block.size}</Data>
      </Entry>
      <Entry>
        <Label>Weight</Label>
        <Data>{block.weight}</Data>
      </Entry>
      <Entry>
        <Label>Version</Label>
        <Data>{block.version}</Data>
      </Entry>
      <Entry>
        <Label>Merkle Root</Label>
        <Data>
          <Hash>{block.merkleRoot}</Hash>
        </Data>
      </Entry>
      <Entry>
        <Label>Nonce</Label>
        <Data>{block.nonce}</Data>
      </Entry>
    </Root>
  );
};

class BlockContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  state = {};

  async fetchBlock(id) {
    const { dispatchFetchBlock } = this.props;

    const result = await dispatchFetchBlock({ id });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to load block. : ${result.payload.message}.`
      );
      return;
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchBlock(params.id);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;

    if (params.id !== prevProps.match.params.id) {
      this.fetchBlock(params.id);
    }
  }

  render() {
    const { block } = this.props;
    return <div>{block && <BlockAnalysis block={block} />}</div>;
  }
}

const mapStateToProps = ({ explorer }) => ({
  block: explorer.block.block
});

const mapDispatchToProps = {
  dispatchFetchBlock: fetchBlock
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BlockContainer)
);
