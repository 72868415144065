import styled, { css } from 'styled-components';
import { Button } from 'components/button';

export const ModalContent = styled.div`
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.02);
  position: relative;
  border-radius: 4px;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s;
  padding: 32px 24px 24px;
`;

export const Modal = styled.div`
  backface-visibility: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2000;
  ${p =>
    p.isVisible &&
    css`
      visibility: visible;
      > ${ModalContent} {
        opacity: 1;
        transform: scale(1);
      }
    `};
`;

export const SmallModal = Modal.extend`
  max-width: 450px;
  h2 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
  }
`;

export const ModalOverlay = styled.div`
  background: rgba(239, 239, 239, 0.9);
  height: 100%;
  left: 0;
  opacity: ${p => (p.isVisible ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 1000;
`;

export const ModalButtons = styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-end;
  padding: 8px 24px 16px;
  ${Button} {
    margin-right: 32px;
  }
`;

export const CancelButton = styled(Button)`
  color: #aaa;
  margin-right: 32px;
  &:hover {
    color: #444;
  }
`;
