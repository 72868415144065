import { handleActions } from 'redux-actions';
import { fetchClusters } from './actions';

const clustersReducer = handleActions(
  {
    [fetchClusters.REQUEST_TYPE]: state => ({
      ...state,
      isLoading: true
    }),

    [fetchClusters.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      isLoading: false,
      list: action.payload.list
    }),
    [fetchClusters.FAILURE_TYPE]: state => ({
      ...state,
      isLoading: false
    })
    /*[deleteCluster.SUCCESS_TYPE]: (state, action) => ({
      ...state,
      list:_.filter(state.list, (item)=>(item._id!==action.payload))
    }),
    [addCluster.SUCCESS_TYPE]: (state, action) => {
      return({
      ...state,
      list:[...state.list,action.payload]
      }
    )},*/
  },
  {
    isLoading: false,
    list: null
  }
);

export default clustersReducer;
