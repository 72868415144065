import { combineReducers } from 'redux';
import authReducer from 'containers/auth/reducer';
import appReducer from 'containers/app/reducer';
import visualizerReducer from 'containers/visualizer/reducer';
import explorerReducer from 'containers/explorer/reducer';
import clusterReducer from 'containers/cluster/reducer';
import clustersReducer from 'containers/cluster-manager/reducer';

export default combineReducers({
  auth: authReducer,
  app: appReducer,
  visualizer: visualizerReducer,
  explorer: explorerReducer,
  cluster: clusterReducer,
  clusters: clustersReducer
});
