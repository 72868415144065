import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { ClusterLink } from 'components/cluster';
import { Tags } from 'components/tags';
import Commands from './commands';

const Root = styled.div`
  display: flex;
`;
const Info = styled.div`
  flex-basis: 100%;
`;
const Address = styled.div`
  color: blue;
  padding: 5px;
  font-size: smaller;
`;

const ClusterHash = styled.div`
  color: orange;
  padding: 5px;
  font-size: smaller;
`;

export default ({ address, onDeleteTag, onSaveTag }) => {
  if (!address) {
    return null;
  }

  return (
    <Root>
      <Info>
        <Address>
          <a
            href={`https://blockchain.info/address/${address.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address.address}
          </a>
        </Address>
        {address.clusterHash && (
          <ClusterHash>
            <ClusterLink hash={address.clusterHash}>
              {address.clusterHash}
            </ClusterLink>
          </ClusterHash>
        )}
        <Tags onDelete={onDeleteTag} tags={_.get(address, 'meta.tags')} />
      </Info>
      <Commands address={address} onSaveTag={onSaveTag} />
    </Root>
  );
};
