import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { format as numberFormat } from 'd3-format';
import { fromSatoshis } from 'util/btc';
import { TaggedAddressLink } from 'components/address';
import { ClusterLink } from 'components/cluster';

const AccountNameRoot = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccountName = ({ entry }) => {
  if (!entry || !entry.referenceAddress) {
    return null;
  }
  return (
    <AccountNameRoot title={entry.referenceAddress.hash}>
      {entry.referenceAddress.cluster ? (
        <ClusterLink hash={entry.referenceAddress.hash}>
          {entry.referenceAddress.name}
        </ClusterLink>
      ) : (
        <TaggedAddressLink tag={entry.referenceAddress} />
      )}
    </AccountNameRoot>
  );
};

export const Amount = styled.div`
  color: ${props => (props.value < 0 ? 'red' : 'black')};
`;

export const DollarValue = ({ value }) => {
  if (!_.isFinite(value)) {
    return null;
  }

  return <span>{numberFormat(',.2f')(value)}</span>;
};

export const BTCValue = ({ value }) => {
  return <span>{numberFormat(',.5f')(fromSatoshis(value))}</span>;
};

export const LedgerValue = ({ value, showUSD }) => {
  if (value.btc === undefined) {
    return null;
  }
  return (
    <Amount value={value.btc}>
      {showUSD ? (
        <DollarValue value={value.dollars} />
      ) : (
        <BTCValue value={value.btc} />
      )}
    </Amount>
  );
};
