// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ColoredLink = styled(Link)`
  color: hotpink;
`;

const Root = styled.span``;

type Props = {
  children?: React$Node,
  hash?: ?string
};

class ClusterLink extends Component<Props> {
  render() {
    const { hash, children } = this.props;

    return (
      <Root title={hash}>
        <ColoredLink to={`/cluster/${hash}`}>{children}</ColoredLink>
      </Root>
    );
  }
}

export default ClusterLink;
