import { createAPIAction } from 'util/api';

export const clusterTx = createAPIAction('visualizer_clustertx', {
  endpoint: ({ id }) => `/tx/${id}/cluster`,
  method: 'POST'
});

export const clusterAddresses = createAPIAction('visualizer_cluster', {
  endpoint: '/clusters',
  method: 'POST',
  body: ({ addresses }) => ({ addresses })
});
