// @flow
import React from 'react';
import IconBase from './base';

const ErrorIcon = (props) => (
  <IconBase {...props} viewBox="0 0 512 475.43">
    <path
      d="M0,493.71H512L256,18.29Zm283.43-73.14H228.57V365.71h54.86Zm0-91.43H228.57V219.43h54.86Z"
      transform="translate(0 -18.29)"
    />
  </IconBase>
);

export default ErrorIcon;
