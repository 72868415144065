import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { getSelectedElements, default as CytoGraph } from './cyto-graph';
import { graphFromNetwork } from './graph-builder';
import { CommandBar } from './command-bar';

const Root = styled.div``;
const Graph = styled.div`
  border-width: 2px;
  border-style: solid;
  border-color: #275384;
`;

class NetworkGraph extends Component {
  constructor(props) {
    super(props);
    this.cytoCtl = null;

    this.setCytoCtlRef = element => {
      this.cytoCtl = element;
    };
  }

  state = {
    graphData: []
  };

  generateGraph(network) {
    this.setState(() => ({
      graphData: graphFromNetwork(network)
    }));
    console.log('***data generated***');
  }

  componentDidMount() {
    const { network } = this.props;
    console.log('did mount generating graph data');
    this.generateGraph(network);
  }

  componentWillReceiveProps(nextProps) {
    const { network } = this.props;
    if (!nextProps.network) {
      this.setState(() => ({
        graphData: null
      }));
      return;
    }

    if (network.primaryAddress === nextProps.network.primaryAddress) {
      const txDifferences = _.differenceWith(
        nextProps.network.txs,
        network.txs,
        (n, o) => n.hash === o.hash
      );

      if (txDifferences.length === 0) {
        return;
      }
    }
    console.log('receive props regenerating graph data');
    this.generateGraph(nextProps.network);
  }

  render() {
    const {
      onItemClick,
      startTime,
      endTime,
      showHidden,
      onCluster,
      onSaveGraph,
      onAddAddress,
      onRestoreGraph
    } = this.props;

    if (this.cytoCtl) {
      this.cytoCtl.filterGraph({
        firstTxTime: startTime,
        lastTxTime: endTime,
        showHidden
      });
    }

    return (
      <Root>
        <CommandBar
          onFit={() => this.cytoCtl.fit()}
          onCenter={() => this.cytoCtl.center()}
          onFindPrimary={() => this.cytoCtl.findPrimary()}
          onHideSelected={() => this.cytoCtl.hideSelected()}
          onRelayout={() => this.cytoCtl.relayout()}
          onCluster={() =>
            onCluster(getSelectedElements(this.cytoCtl.getSelected()))
          }
          onAddAddress={() => {
            if (onAddAddress) {
              onAddAddress(getSelectedElements(this.cytoCtl.getSelected()));
            }
          }}
          onSaveGraph={() => onSaveGraph(this.cytoCtl.toJson())}
          onRestoreGraph={() => this.cytoCtl.toJson(onRestoreGraph())}
        />
        <Graph>
          <CytoGraph
            onItemClick={onItemClick}
            data={this.state.graphData}
            ref={this.setCytoCtlRef}
          />
        </Graph>
      </Root>
    );
  }
}

export default NetworkGraph;
