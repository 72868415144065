// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled.span``;
const SimpleLink = styled(Link)`
  text-decoration: none;
`;

type Props = {
  children?: React$Node,
  hash?: ?string
};

export class TxLink extends Component<Props> {
  render() {
    const { hash, children } = this.props;

    return (
      <Root title={hash}>
        <SimpleLink to={`/tx/${hash}`}>{children}</SimpleLink>
      </Root>
    );
  }
}

export const TaggedTxLink = ({ tag }) => (
  <React.Fragment>
    {tag && <TxLink hash={tag.hash}>{tag.name}</TxLink>}
  </React.Fragment>
);

export default TxLink;
