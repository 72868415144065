import React, { Component } from 'react';
import { withFormik, Field, Form } from 'formik';
import styled from 'styled-components';
import { SearchIcon } from 'components/icons';

const IconButton = styled.div`
  display: inline-block;
  color: white;
  border: 1px solid #090;
  background: #090;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  vertical-align: middle;
  max-width: 100px;
  padding: 3px 5px;
  text-align: center;
`;
const SuccessText = styled.div`
  color: white;
`;

const SearchWrapper = styled.div`
  display: flex;
`;
const SearchText = styled(Field)`
  flex: 1;
`;
const SearchButton = styled.div`
  flex: 0 0 30px;
`;

class SearchForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <SearchWrapper>
          <SearchText type="text" name="searchText" />
          <SearchButton>
            <IconButton onClick={handleSubmit}>
              <SuccessText>
                <SearchIcon size="medium" />
              </SuccessText>
            </IconButton>
          </SearchButton>
        </SearchWrapper>
      </Form>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => ({
    searchText: ''
  }),
  validate: values => {
    const errors = {};

    if (!values.searchText) {
      errors.searchText = 'Required';
    }

    return errors;
  },
  handleSubmit: async (values, { props }) => {
    props.onSubmit(values);
  }
})(SearchForm);
