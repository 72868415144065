import React, { Component } from 'react';
import { SmallModal, ModalContent } from 'components/modal';
import { PortalWithState } from 'react-portal';
import { Button } from 'components/button';
import AddForm from './add-form';

class AddTag extends Component {
  render() {
    const { onSaveTag, initialValues, description } = this.props;
    return (
      <PortalWithState closeOnEsc closeOnOutsideClick>
        {({ openPortal, closePortal, isOpen, portal }) => (
          <React.Fragment>
            <Button size="small" buttonType="primary" onClick={openPortal}>
              Add Tag
            </Button>
            {portal(
              <SmallModal isVisible={isOpen}>
                <ModalContent>
                  <AddForm
                    initialValues={initialValues}
                    onSubmit={onSaveTag}
                    closePortal={closePortal}
                    description={description}
                  />
                </ModalContent>
              </SmallModal>
            )}
          </React.Fragment>
        )}
      </PortalWithState>
    );
  }
}

export default AddTag;
