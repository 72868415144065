import React, { Component } from 'react';
import { PortalWithState } from 'react-portal';
import { Button } from 'components/button';
import ConfirmModal from './confirm-modal';

export default class ConfirmButton extends Component {
  render() {
    const {
      children,
      confirmMessage,
      submittingText,
      confirmButtonType,
      onConfirm,
      confirmButtonText,
      ...props
    } = this.props;

    return (
      <PortalWithState closeOnEsc closeOnOutsideClick>
        {({ openPortal, closePortal, isOpen, portal }) => (
          <React.Fragment>
            <Button onClick={openPortal} {...props}>
              {children}
            </Button>
            {portal(
              <ConfirmModal
                confirmMessage={confirmMessage}
                onConfirm={onConfirm}
                closePortal={closePortal}
                show={isOpen}
                submittingText={submittingText}
                confirmButtonType={confirmButtonType}
                confirmButtonText={confirmButtonText}
              />
            )}
          </React.Fragment>
        )}
      </PortalWithState>
    );
  }
}
