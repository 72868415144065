import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from 'components/icons';
import { ConfirmButton } from 'components/modal';
import { WarnText } from 'components/util';

const Tag = styled.span`
  padding-right: 5px;
`;

const Root = styled.div`
  color: green;
  padding: 5px;
`;

export const Tags = ({ onDelete, tags }) => {
  if (!tags) {
    return null;
  }
  return (
    <Root>
      {_.map(tags, (tag, index) => (
        <Tag key={index}>
          {tag.name}
          {onDelete && (
            <ConfirmButton
              size="mini"
              confirmMessage={`Are you sure you want to delete the tag ${
                tag.name
              }?`}
              confirmButtonText="Delete"
              onConfirm={() => onDelete(tag._id)}
            >
              <WarnText>
                <CloseIcon size="medium" />
              </WarnText>
            </ConfirmButton>
          )}
        </Tag>
      ))}
    </Root>
  );
};

export default Tags;
