import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { AddressSummary } from 'components/address';
import { TxDetails } from 'components/tx';

const Root = styled.div`
  margin: 5px;
`;
const TransferRoot = styled.div`
  display: flex;
  overflow: hidden;
`;
const Tx = styled.div`
  margin: 2px 5px;
  flex: 0 0 50%;
`;
const Address = styled.div`
  margin: 2px 5px;
  flex: 0 0 45%;
`;
const SubDetails = ({ item, onChangeChange }) => {
  if (!item) {
    return null;
  }

  const isChange = _.get(
    item,
    `sourceReferenceObject.outputs[${item.idx}].meta.isChange`,
    false
  );
  return (
    <div>
      {`idx: ${item.idx} input: ${item.isInput}`}
      {!item.isInput && (
        <span>
          {' '}
          Change
          <input
            type="checkbox"
            onChange={e =>
              onChangeChange({
                tx: item.sourceReferenceObject,
                idx: item.idx,
                isChange: !isChange,
                item
              })
            }
            checked={isChange}
            value={item.idx}
          />
        </span>
      )}
    </div>
  );
};

const Transfer = ({ item, onCluster, onSaveTag, onChangeChange }) => {
  let credit = false;
  let address = item.sourceReferenceObject;
  let tx = item.targetReferenceObject;
  if (item.targetIsAddress) {
    credit = true;
    tx = item.sourceReferenceObject;
    address = item.targetReferenceObject;
  }

  if (credit) {
    return (
      <TransferRoot>
        <Tx>
          <TxDetails tx={tx} onCluster={onCluster} onSaveTag={onSaveTag} />
          <SubDetails item={item} onChangeChange={onChangeChange} />
        </Tx>
        <Address>
          <AddressSummary address={address} onSaveTag={onSaveTag} />
        </Address>
      </TransferRoot>
    );
  } else {
    return (
      <TransferRoot>
        <Address>
          <AddressSummary address={address} onSaveTag={onSaveTag} />
        </Address>
        <Tx>
          <TxDetails tx={tx} onCluster={onCluster} onSaveTag={onSaveTag} />
          <SubDetails item={item} />
        </Tx>
      </TransferRoot>
    );
  }
};

const FocusItem = ({ item, onCluster, onSaveTag, onChangeChange }) => {
  if (!item) {
    return null;
  }

  if (item.type === 'addr') {
    return (
      <AddressSummary
        address={item && item.referenceObject ? item.referenceObject : null}
        onSaveTag={onSaveTag}
      />
    );
  } else if (item.type === 'tx') {
    return (
      <TxDetails
        tx={item && item.referenceObject}
        onCluster={onCluster}
        onSaveTag={onSaveTag}
      />
    );
  } else {
    return (
      <Transfer
        item={item}
        onCluster={onCluster}
        onSaveTag={onSaveTag}
        onChangeChange={onChangeChange}
      />
    );
  }
};

const WrappedFocusItem = props => {
  return (
    <Root>
      <FocusItem {...props} />
    </Root>
  );
};

export default WrappedFocusItem;
