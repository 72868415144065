import * as polished from 'polished';

export const TEXT_COLOR = '#353D4B';
export const BORDER_COLOR = '#E5E9F1';
export const CONTENT_BACKGROUND_COLOR = '#F9F9FB';
export const MUTED_COLOR = '#8C96A8';
export const HIGHLIGHT_COLOR = '#0069FF';
export const PLACEHOLDER_COLOR = '#B8BCC3';
export const ERROR_COLOR = '#ED4F32';
export const SUCCESS_COLOR = '#00AB6B';

// ******* FORM STYLES *******

export const FIELD_BORDER_COLOR = BORDER_COLOR;
export const FIELD_ERROR_BORDER_COLOR = ERROR_COLOR;
export const FIELD_FOCUS_BORDER_COLOR = '#C3D0E5';
export const FIELD_HEIGHT = 52;
export const FIELD_SIDE_PADDING = 16;

// BORDER RADIUS

export const radiusBottomOnly = (size = 4) =>
  `0 0 ${size}px ${size}px`;

export const radiusTopOnly = (size = 4) =>
  `${size}px ${size}px 0 0`;

// HELPERS

export const fieldBase = () => ({
  border: 0,
  outline: 'none',
  width: '100%',
  ...polished.placeholder({ color: PLACEHOLDER_COLOR }),
});

export const focus = (
  borderColor = FIELD_FOCUS_BORDER_COLOR,
  outlineColor = HIGHLIGHT_COLOR,
) => {
  const shadowColor = polished.rgba({
    ...polished.parseToRgb(outlineColor),
    alpha: .1,
  });

  const shadowCss = `0 0 0 3px ${shadowColor}`;

  return {
    'border-color': borderColor,
    'box-shadow': `inset 0 1px 1px rgba(0, 0, 0, .075), ${shadowCss}`,
  };
};

export const card = (
  background = '#FFF',
) => ({
  background,
  border: `1px solid ${BORDER_COLOR}`,
  'border-radius': '4px',
});
