import React from 'react';
import styled from 'styled-components';
import TxTimes from './tx-times';

const TimeRangeRoot = styled.div`
  display: flex;
  margin: 5px;
`;
const TimeRangeTime = styled.div``;
const TimeRangeSpacer = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;
const TimeRange = ({ txTimes, start, end, onChangeStart, onChangeEnd }) => {
  return (
    <TimeRangeRoot>
      <TimeRangeTime>
        <TxTimes
          value={start}
          txTimes={txTimes}
          onChange={e => onChangeStart(e.target.value)}
          onClickStart={() => onChangeStart(txTimes.list[0])}
          onClickEnd={() =>
            onChangeStart(txTimes.list[txTimes.list.length - 1])
          }
        />
      </TimeRangeTime>
      <TimeRangeSpacer>-</TimeRangeSpacer>
      <TimeRangeTime>
        <TxTimes
          value={end}
          txTimes={txTimes}
          onChange={e => onChangeEnd(e.target.value)}
          onClickStart={() => onChangeEnd(txTimes.list[0])}
          onClickEnd={() => onChangeEnd(txTimes.list[txTimes.list.length - 1])}
        />
      </TimeRangeTime>
    </TimeRangeRoot>
  );
};

export default TimeRange;
