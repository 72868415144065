import React from 'react';
import styled from 'styled-components';
import {
  FullScreen,
  CrossHair,
  ArrowCircle,
  CloseIcon
} from 'components/icons';
import { SimpleButton } from 'components/button';
import { Button } from 'components/button';

const Root = styled.div`
  display: flex;
  margin: 5px 0px;
`;
const ButtonsLeft = styled.div`
  display: flex;
  flex-basis: 100%;
`;
const ButtonsRight = styled.div`
  display: flex;
`;

export const CommandBar = ({
  cytoCtl,
  onFit,
  onCenter,
  onFindPrimary,
  onHideSelected,
  onRelayout,
  onCluster,
  onSaveGraph,
  onRestoreGraph,
  onAddAddress
}) => {
  return (
    <Root>
      <ButtonsLeft>
        <SimpleButton title="Fit" width="12" onClick={onFit}>
          <FullScreen size="medium" />
        </SimpleButton>
        <SimpleButton title="Center" width="12" onClick={onCenter}>
          <CrossHair size="medium" />
        </SimpleButton>
        <SimpleButton title="Find Primary" width="12" onClick={onFindPrimary}>
          P
        </SimpleButton>
        <SimpleButton title="Hide" width="12" onClick={onHideSelected}>
          <CloseIcon size="medium" />
        </SimpleButton>
        <SimpleButton title="Re-Layout" width="12" onClick={onRelayout}>
          <ArrowCircle size="medium" />
        </SimpleButton>
        <SimpleButton title="Add Address" width="12" onClick={onAddAddress}>
          +
        </SimpleButton>
      </ButtonsLeft>
      <ButtonsRight>
        <Button size="small" buttonType="primary" onClick={onCluster}>
          Cluster
        </Button>
        <Button size="small" buttonType="primary" onClick={onSaveGraph}>
          Save
        </Button>
        <Button size="small" buttonType="primary" onClick={onRestoreGraph}>
          Load
        </Button>
      </ButtonsRight>
    </Root>
  );
};
