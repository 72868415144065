import jwtDecode from 'jwt-decode';

export const TOKEN_STORAGE_KEY = 'txledger_access_token';

export const getStoredToken = () => localStorage.getItem(TOKEN_STORAGE_KEY);

export const storeToken = accessToken =>
  localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);

export const clearStoredToken = () =>
  localStorage.removeItem(TOKEN_STORAGE_KEY);

export const isTokenCurrent = (stateToken, storedToken = getStoredToken()) => {
  let current = false;

  if (stateToken && storedToken) {
    try {
      const stateTokenUser = jwtDecode(stateToken).sub;
      const storedTokenUser = jwtDecode(storedToken).sub;

      current = stateTokenUser === storedTokenUser;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return current;
};
