// @flow
import React from 'react';
import IconBase from './base';

const InfoIcon = (props) => (
  <IconBase {...props}>
    <circle cx="250.46" cy="152.62" r="24.62" />
    <polygon points="275.69 364.31 275.69 206.77 216.62 206.77 216.62 216.62 236.31 216.62 236.31 364.31 216.62 364.31 216.62 374.15 295.38 374.15 295.38 364.31 275.69 364.31" />
    <path d="M256,0C114.58,0,0,114.58,0,256S114.58,512,256,512,512,397.42,512,256,397.42,0,256,0Zm0,490.71C126.65,490.71,21.29,385.48,21.29,256S126.52,21.29,256,21.29,490.71,126.52,490.71,256,385.35,490.71,256,490.71Z" />
  </IconBase>
);

export default InfoIcon;
