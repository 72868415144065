import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchTx } from 'containers/visualizer/tx-analysis/actions';
import { TxLink } from 'components/tx';
import { AddressLink } from 'components/address';
import { BlockDateTime } from 'components/btc';
import { CreditDebit } from 'components/util';
import { format } from 'd3-format';
import { fromSatoshis } from 'util/btc';

const Root = styled.div``;
const Label = styled.div`
  flex: 0 0 40%;
`;
const Data = styled.div`
  flex: 0 0 60%;
`;
const Hash = styled.span`
  font-size: smaller;
`;
const InputOutput = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
  width: 95%;
`;
const PutListContainer = styled.div`
  flex: 0 0 40%;
  margin: 0px 10px;
`;
const Info = styled.div`
  display: flex;
  margin: 10px 5px 0px 5px;
`;
const Technical = styled.div`
  flex: 0 0 50%;
`;
const TechEntry = styled.div`
  display: flex;
  width: 95%;
  background: ${props => (props.hilite ? '#fafafa' : 'default')};
  border-style: ${props => (props.hilite ? 'solid' : 'none')};
  border-width: 1px 0px;
  border-color: #ddd;
  padding: 5px 10px;
`;
const Financial = styled.div`
  flex: 0 0 50%;
`;
const FinEntry = styled.div`
  display: flex;
  width: 95%;
  background: ${props => (props.hilite ? '#fafafa' : 'default')};
  border-style: ${props => (props.hilite ? 'solid' : 'none')};
  border-width: 1px 0px;
  border-color: #ddd;
  padding: 5px 10px;
`;
const Highlight = styled.div`
  border-width: 1px 0px;
  border-color: #ddd;
  border-style: solid;
  padding: 5px 0px;
  background: #fafafa;
`;

const RightNumber = styled.div`
  text-align: right;
`;

const Put = styled.div`
  display: flex;
`;
const PutAddress = styled.div`
  flex: 1;
`;
const PutValue = styled.div`
  width: 200px;
  text-align: right;
  font-size: smaller;
`;

export const PutList = ({ puts, debit }) => {
  return (
    <PutListContainer>
      {_.map(puts, (put, index) => (
        <Put key={index}>
          <PutAddress>
            <AddressLink hash={put.address}>
              <Hash>{put.address}</Hash>{' '}
            </AddressLink>
          </PutAddress>
          <PutValue>
            <CreditDebit debit={debit}>
              <Value8 value={put.value} />
            </CreditDebit>
          </PutValue>
        </Put>
      ))}
    </PutListContainer>
  );
};

export const formatBitcoinAmount = amount =>
  amount === null || amount === undefined
    ? ''
    : format(',.8f')(fromSatoshis(amount));

export const Value8 = ({ value }) => (
  <RightNumber>{formatBitcoinAmount(value)}</RightNumber>
);

const TxAnalysis = ({ tx }) => {
  return (
    <Root>
      <Highlight>
        <TxLink hash={tx.hash}>
          <Hash>{tx.hash}</Hash>
        </TxLink>
      </Highlight>
      <InputOutput>
        <PutList puts={tx.inputs} />
        <PutList puts={tx.outputs} debit={true} />
      </InputOutput>
      <Info>
        <Technical>
          <TechEntry hilite="true">
            <Label>Time</Label>
            <Data>
              <BlockDateTime time={tx.time} />
            </Data>
          </TechEntry>
          <TechEntry>
            <Label>Size</Label>
            <Data>{tx.size}</Data>
          </TechEntry>
          <TechEntry hilite="true">
            <Label>Weight</Label>
            <Data>{tx.weight}</Data>
          </TechEntry>
          <TechEntry>
            <Label>Version</Label>
            <Data>{tx.version}</Data>
          </TechEntry>
        </Technical>
        <Financial>
          <FinEntry hilite="true">
            <Label>Value In</Label>
            <Data>
              <Value8 value={tx.valueIn} />
            </Data>
          </FinEntry>
          <FinEntry>
            <Label>Value Out</Label>
            <Data>
              <Value8 value={tx.valueOut} />
            </Data>
          </FinEntry>
          <FinEntry hilite="true">
            <Label>Fees</Label>
            <Data>
              <Value8 value={tx.fees} />
            </Data>
          </FinEntry>
        </Financial>
      </Info>
    </Root>
  );
};

class TxContainer extends Component {
  static contextTypes = {
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func
  };

  state = {};

  async fetchTx(id) {
    const { dispatchFetchTx } = this.props;

    const result = await dispatchFetchTx({ id });
    if (result.error) {
      this.context.showErrorNotification(
        `Failed to load tx. : ${result.payload.message}.`
      );
      return;
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.fetchTx(params.id);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;

    if (params.id !== prevProps.match.params.id) {
      this.fetchTx(params.id);
    }
  }

  render() {
    const { tx } = this.props;
    return <div>{tx && <TxAnalysis tx={tx} />}</div>;
  }
}

const mapStateToProps = ({ explorer }) => ({
  tx: explorer.tx.tx
});

const mapDispatchToProps = {
  dispatchFetchTx: fetchTx
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TxContainer)
);
