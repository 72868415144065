import React from 'react';
import styled from 'styled-components';
import AddTag from 'components/add-tag';

const Root = styled.div``;

export default ({ address, onSaveTag }) => {
  if (!address) {
    return null;
  }

  return (
    <Root>
      {onSaveTag && (
        <AddTag
          initialValues={{ tagText: '' }}
          onSaveTag={data => onSaveTag({ address, tagText: data.tagText })}
          description={<div>Address Tag</div>}
        />
      )}
    </Root>
  );
};
